/* 2022-02-24 인수인계
gnb > 더보기 페이지 css입니다. */

div.more_area {
  padding: 0 16px;
  padding-top: 1rem;
}
div.more_area div.more_my_info {
  display: flex;
  align-items: center;
  gap: 10px;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.more_area div.more_my_info p.more_my_info_profile_img {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-size: cover !important;
  background-position: center !important;
  border: 1px solid #eaeaea;
}
div.more_area div.more_my_info div.more_my_info_col {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
div.more_area div.more_my_info div.more_my_info_col h1 {
  font-size: 14px;
  font-weight: 500;
}
div.more_area div.more_my_info div.more_my_info_col span {
  font-size: 12px;
  color: #888888;
}
div.more_area ul.more_cal_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
}
div.more_area ul.more_cal_list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  width: 33.333%;
  cursor: pointer;
}
div.more_area ul.more_cal_list li h1 {
}
div.more_area ul.more_cal_list li span {
  font-size: 10px;
  color: #888888;
}
div.more_area ul.more_cal_list li h1 {
  font-size: 20px;
  text-align: center;
}
div.more_area div.data_save_wrapper {
}
div.more_area div.data_save_wrapper h1 {
  font-size: 12px;
  margin-top: 1.5rem;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.more_area div.data_save_wrapper div.none_data_save {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid #dddddd;
}
div.more_area div.data_save_wrapper div.none_data_save span {
  color: #888888;
  font-size: 12px;
}
div.more_area div.data_save_wrapper div.none_data_save a {
  font-size: 14px;
  color: #4587f5;
  font-weight: 500;
  margin-top: 14px;
}
div.more_area div.data_save_wrapper div.none_data_save a:hover {
}
div.more_area div.data_save_wrapper div.none_data_save a:active {
}
div.more_area div.data_save_wrapper div.none_data_save a:focus {
}
div.more_area div.payment_wrapper {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 1.5rem;
}
div.more_area div.payment_wrapper h1 {
  font-size: 12px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
div.more_area div.payment_wrapper ul.payment_list {
  display: flex;
  align-items: center;
  gap: 5px;
}
div.more_area div.payment_wrapper ul.payment_list li {
  width: 100%;
  height: 62px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
  cursor: pointer;
}
div.more_area div.payment_wrapper ul.payment_list p.payment_icon_2 {
  width: 32px;
  height: 32px;
  background: url(../images/icon_payment_2.svg);
  background-size: cover;
  background-position: center;
}
div.more_area div.payment_wrapper ul.payment_list span {
}
div.more_area div.payment_wrapper ul.payment_list p.payment_icon_1 {
  width: 32px;
  height: 32px;
  background: url(../images/icon_payment_1.svg);
  background-size: cover;
  background-position: center;
}
div.more_area div.payment_wrapper ul.payment_list span {
  font-size: 14px;
  color: #666666;
}
div.more_area ul.account_setting_wrapper {
}
div.more_area ul.account_setting_wrapper li {
  height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}
div.more_area ul.account_setting_wrapper li a {
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: right;
}
div.more_area ul.account_setting_wrapper li a:hover {
}
div.more_area ul.account_setting_wrapper li a:active {
}
div.more_area ul.account_setting_wrapper li a:focus {
}
div.more_area button.logout_button {
  text-align: center;
  margin: 0 auto;
  display: flex;
  color: #bbbbbb;
  font-weight: 500;
  margin-top: 2rem;
}
div.more_area button.logout_button:hover {
}
div.more_area button.logout_button:active {
}
div.more_area button.logout_button:focus {
}
div.data_save_area {
}
div.data_save_area ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  border-bottom: 1px solid #dddddd;
  width: 100%;
}
div.data_save_area ul li {
  width: 33.333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  cursor: pointer;
}
div.data_save_area ul li img {
}
div.data_save_area ul li span {
  font-size: 12px;
  color: #888888;
}

div.pc_right_area {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
  padding-left: 380px;
  padding-right: 0;
}

div.pc_right_area div.pc_right_area_top .right {
  position: absolute;
  right: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.pc_right_area div.pc_right_area_top .right img {
  cursor: pointer;
}
div.pc_right_area div.pc_right_area_top {
  min-height: 52px;
  display: flex;
  align-items: center;
  position: relative;
}
div.pc_right_area div.pc_right_area_top div.left {
  position: absolute;
  left: 16px;
}
div.pc_right_area div.pc_right_area_top div.left img {
  cursor: pointer;
}
div.pc_right_area div.pc_right_area_top h1.right_area_title {
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.pc_right_area div.right_area_my_info {
  max-width: 734px;
  margin: 0 auto;
  width: 100%;
}
div.pc_right_area div.right_area_my_info p.right_area_my_info_profile {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  border-radius: 100%;
  background-size: cover !important;
  background-position: center !important;
  background: url("../images/icon_profile_basic.svg") no-repeat;
  border: 1px solid #eaeaea;
}
div.pc_right_area div.right_area_my_info div.right_area_my_info_profile_col {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: url(../images/icon_right_s.svg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
}
div.pc_right_area div.right_area_my_info div.right_area_my_info_profile_col h1 {
  font-size: 14px;
  font-weight: 500;
}
div.pc_right_area
  div.right_area_my_info
  div.right_area_my_info_profile_col
  span {
  font-size: 12px;
  color: #888888;
  max-width: 90%;
}

div.pc_right_area div.right_area_my_info div.right_b_info_wrapper {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dddddd;
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper h1 {
}
div.pc_right_area
  div.right_area_my_info
  div.right_b_info_wrapper
  div.right_b_info_wrapper_none,
.right_b_info_wrapper_rejected {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  display: none;
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper h1 {
  font-size: 14px;
  color: #888888;
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper h1 span {
  font-weight: 500;
  color: #111111;
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper a {
  color: #4587f5;
  font-weight: 500;
  font-size: 14px;
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper a:hover {
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper a:active {
}
div.pc_right_area div.right_area_my_info div.right_b_info_wrapper a:focus {
}
div.pc_right_area div.right_area_my_info div.right_cal_list {
}
div.pc_right_area div.right_area_my_info div.right_cal_list h1 {
  font-size: 12px;
  color: #888888;
  padding: 1.5rem 0;
}
div.pc_right_area div.right_area_my_info div.right_cal_list ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
div.pc_right_area div.right_area_my_info div.right_cal_list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 12px 12px;
  width: 100%;
  height: 62px;
  background: #f2f2f2;
  border-radius: 12px;

  /* Inside Auto Layout */
  order: 0;
  margin: 0px 8px;
  gap: 10px;
  cursor: pointer;
  margin: 0;
}
div.pc_right_area div.right_area_my_info div.right_cal_list li p {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: url(../images/icon_req_1.svg) no-repeat;
  background-size: cover !important;
  background-position: center !important;
  display: flex;
  align-items: center;
}

div.pc_right_area
  div.right_area_my_info
  div.right_cal_list
  li:nth-of-type(2)
  p {
  background: url(../images/icon_req_2.svg) no-repeat;
}

div.pc_right_area div.right_area_my_info div.right_cal_list li span {
  font-size: 14px;
  color: #666666;
}

.right_area_my_info_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

div.b_in_progress {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  display: none;
}
div.b_in_progress img {
}
div.b_in_progress span {
  text-align: center;
  font-size: 12px;
  color: #888888;
}
div.b_in_progress span br {
}
div.more_b_info_wrapper {
}
div.more_b_info_wrapper ul {
  margin-top: 1rem;
  width: 100%;
}
div.more_b_info_wrapper ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
}
div.more_b_info_wrapper ul li span {
}
div.more_b_info_wrapper ul li h1 {
  color: #111111 !important;
}
div.more_b_info_wrapper ul li button {
  font-size: 12px;
  text-decoration: underline;
  color: #888888;
}
div.more_b_info_wrapper ul li button:hover {
}
div.more_b_info_wrapper ul li button:active {
}
div.more_b_info_wrapper ul li button:focus {
}
div.more_b_info_wrapper ul li span {
  color: #888888;
}
div.edit_wrapper {
  max-width: 464px;
  margin: 0 auto;
  width: 100%;
}
div.edit_wrapper div.profile_img_input_wrapper {
  max-width: 450px;
  width: 100%;
}
div.edit_wrapper div.profile_img_input_wrapper label {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center !important;
  background-size: cover !important;
  background: url("../images/icon_profile_basic.svg") no-repeat;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  margin: 3rem auto;
  border: 1px solid #eaeaea;
}
div.edit_wrapper div.profile_img_input_wrapper label input {
  display: none;
}
div.edit_wrapper div.profile_img_input_wrapper label p.icon_photo {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url(../images/icon_photo.svg) no-repeat;
  background-position: center;
  background-size: cover;
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper div.row {
  width: 100%;
  display: flex;
  flex-direction: column;
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 152px;
  top: 32px;
  background: #4587f5;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin: 8px 0px;
  color: #fff;
  margin: 0 auto;
  margin-top: 2rem;
}
div.edit_wrapper
  div.profile_img_input_wrapper
  div.edit_info_wrapper
  button:hover {
}
div.edit_wrapper
  div.profile_img_input_wrapper
  div.edit_info_wrapper
  button:active {
}
div.edit_wrapper
  div.profile_img_input_wrapper
  div.edit_info_wrapper
  button:focus {
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper h1 {
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper textarea {
  width: 100%;
  height: 44px;
  background: #f2f2f2;
  border-radius: 8px;
  margin: 6px 0px;
  height: 88px;
  padding: 1rem;
  resize: none;
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper input {
  width: 100%;
  height: 44px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  margin: 6px 0px;
  padding-left: 1rem;
}

div.submit_b_wrapper {
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
  margin-top: 2rem;
}
div.submit_b_wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
div.submit_b_wrapper ul li.b_submit_row {
}
div.submit_b_wrapper ul li.b_submit_row h1 {
}
div.submit_b_wrapper ul li.b_submit_row input {
}
div.submit_b_wrapper ul li.b_submit_row > h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.submit_b_wrapper ul li.b_submit_row input {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  align-self: stretch;
  margin: 6px 0px;
  padding-left: 1rem;
}
div.submit_b_wrapper div.b_submit_textArea {
  margin-top: 20px;
  display: flex;
  gap: 4px;
  flex-direction: column;
}
div.submit_b_wrapper div.b_submit_textArea h1 {
  font-size: 14px;
  color: #bbbbbb;
}
div.submit_b_wrapper div.b_submit_textArea h2 {
  font-size: 14px;
  line-height: 24px;
  color: #6c6c6c;
}
div.submit_b_wrapper div.b_submit_textArea h2 span {
  font-weight: 500;
  color: #111111;
}
div.submit_b_wrapper ul.b_submit_file_list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 15px;
  flex-direction: row;
  margin-top: 2rem;
}
div.submit_b_wrapper ul.b_submit_file_list li.added_file {
  width: 92px;
  height: 92px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  position: relative;
  font-size: 0;
  padding-left: 8px;
}
div.submit_b_wrapper ul.b_submit_file_list li.added_file p.file_del_button {
  width: 16px;
  height: 16px;
  background: url(../images/icon_close_s_w.svg) no-repeat;
  background-color: #bbbbbb;
  background-position: center;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
div.submit_b_wrapper ul.b_submit_file_list li.added_file div.file_icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #dddddd;
  background: url(../images/icon_file.svg) no-repeat;
  background-position: center;
  margin-top: 8px;
}
div.submit_b_wrapper ul.b_submit_file_list li.added_file span.file_name {
  font-size: 10px;
  display: block;
  margin-top: 10px;
}
div.submit_b_wrapper ul.b_submit_file_list li.added_file span.file_size {
  font-size: 10px;
  color: #bbbbbb;
}
div.submit_b_wrapper ul.b_submit_file_list li.file_add_button {
  width: 92px;
  height: 92px;
  border-radius: 8px;
  background: url(../images/icon_plus_b.svg) no-repeat;
  background-position: center;
  background-color: #f2f2f2;
  cursor: pointer;
}
div.submit_b_wrapper ul.b_submit_file_list li.file_add_button label {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
}
div.submit_b_wrapper ul.b_submit_file_list li.file_add_button input {
  display: none;
}
div.submit_b_wrapper div.agree_submit_b {
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.submit_b_wrapper div.agree_submit_b label {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 40px;
  width: 80%;
}
div.submit_b_wrapper div.agree_submit_b label input {
  min-width: 28px;
  min-height: 28px;
  border: 1px solid #dddddd;
  border-radius: 100%;
}
div.submit_b_wrapper div.agree_submit_b label span {
  font-size: 14px;
}
div.submit_b_wrapper button.b_submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  max-width: 328px;
  height: 44px;
  left: 68px;
  top: 32px;
  background: #4587f5;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  margin: 0 auto;
  color: #fff;
  margin-top: 40px;
  width: 100%;
}
div.submit_b_wrapper button.b_submit_button:hover {
}
div.submit_b_wrapper button.b_submit_button:active {
}
div.submit_b_wrapper button.b_submit_button:focus {
}

div.submit_b_wrapper div.agree_submit_b label input:checked {
  background: url(../images/icon_checked.svg) no-repeat;
  background-size: cover;
}

div.data_wrapper {
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}

.pc_right_area .data_wrapper {
  padding: 0 20px;
}

div.data_wrapper > div.data_select_row {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  height: 44px;
  gap: 44px;
}
div.data_wrapper > div.data_select_row > div.send_data {
}
div.data_wrapper > div.data_select_row > div.send_data > span {
  font-size: 16px;
  padding-right: 21px;
  background: url(../images/icon_select_bottom.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.data_wrapper > div.data_select_row > div.data_info {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
div.data_wrapper > div.data_select_row > div.data_info > img {
}

div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(1) {
  color: #111111;
  margin-right: 10px;
}

div.data_wrapper > div.data_select_row > div.data_info > span {
  font-size: 12px;
}
div.data_wrapper > div.data_price_row {
}
div.data_wrapper > div.data_price_row > h1 {
  padding: 3rem 0;
}
div.data_wrapper > div.data_price_row > h1 > span {
  font-size: 40px;
  color: #4587f5;
}
div.data_wrapper > div.data_detail_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #f2f2f2;
}
div.payment_history_wrapper > div.data_detail_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.875rem;
}
div.data_wrapper > div.data_detail_select > div.select_sort {
}
div.data_wrapper > div.data_detail_select > div.select_sort > span {
  font-size: 12px;
  padding-right: 21px;
  background: url(../images/icon_select_bottom.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.data_wrapper > div.data_detail_select > div.select_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
}
div.data_wrapper > div.data_detail_select > div.select_right > a {
  font-size: 12px;
  color: #4587f5;
  text-decoration: underline;
}
div.data_wrapper > div.data_detail_select > div.select_right > img {
  cursor: pointer;
}
ul.data_list {
  padding-bottom: 59px;
}
ul.data_list > li {
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  gap: 12px;
  padding: 15px 0;
  display: none;
  cursor: pointer;
}
ul.data_list > li.active {
  display: flex;
}
ul.data_list > li > p.type_1 {
  width: 18px;
  height: 18px;
  background: #4587f5;
  border-radius: 100%;
}

.status_1 {
  background-color: #4587f5 !important;
}
.status_2 {
  background-color: #00cc66 !important;
}
.status_3 {
  background-color: #aabbcc !important;
}
.status_4 {
  background-color: #ffcc00 !important;
}
.status_5 {
  background-color: #ff5555 !important;
}
ul.data_list > li > div.data_list_textArea {
}
ul.data_list > li > div.data_list_textArea > h1 {
  font-size: 14px;
  font-weight: 500;
}
ul.data_list > li > div.data_list_textArea > span {
  font-size: 12px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul.data_list > li > div.data_list_textArea > h1 > span.date_created_at {
  color: #bbbbbb;
  font-size: 10px;
  margin-right: 7px;
}
ul.data_list > li > div.data_list_textArea > h1 > span.data_price_detail {
  font-size: 10px;
  color: #4587f5;
}
ul.data_list > li > p.type_2 {
  width: 18px;
  height: 18px;
  background: #00cc66;
  border-radius: 100%;
}
ul.data_list > li > p.type_3 {
  background: #aabbcc;
}
ul.data_list > li > p.type_4 {
  background: #ffcc00;
}
ul.data_list > li > p.type_5 {
  background: #ff5555;
}
ul.data_list > li > p {
  min-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 100%;
}
div.search_header {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
div.search_header div.info_search_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;
  width: calc(100% - 60px);
  height: 36px;
  left: 0px;
  top: 8px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 8px;
}
div.search_header div.info_search_wrapper img {
  padding-left: 0.5rem;
}
div.search_header div.info_search_wrapper input {
  width: 100%;
  padding-left: 0.5rem;
}
div.search_header div.info_search_wrapper p.del_input_text_button {
}
div.search_header span {
  cursor: pointer;
  font-size: 14px;
  min-width: 60px;
  text-align: center;
  cursor: pointer;
}

.select_data_type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  height: 36px;
  border-radius: 8px;
  max-width: 280px;
  padding: 2px;
  margin: 0 auto;
  width: 100%;
  border-bottom: chocolate;
}
.select_data_type li {
  width: 50%;
  text-align: center;
  font-size: 12px;
  color: #bbbbbb;
  cursor: pointer;
}
.select_data_type li.active {
  height: 100%;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111111 !important;
}

.select_data_type_wrapper {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
}

.confirm_button {
  position: absolute;
  right: 0;
  cursor: pointer;
  color: #4587f5;
}

.data_wrapper_checkable ul li {
  background: url(../images/icon_check.svg) no-repeat;
  background-position: right;
}
.data_wrapper_checkable ul li.on {
  background: url(../images/icon_checked_active.svg) no-repeat;
  background-position: right;
}
div.data_save_area_1 {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  padding-top: 2rem;
}
div.data_save_area_1 h1 {
  font-weight: 300;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 92px;
}
div.data_save_area_1 h1 span {
  font-size: 24px;
  display: block;
  font-weight: 500;
}
div.data_save_area_1 ul {
}
div.data_save_area_1 ul li {
  display: flex;
  align-items: center;
  gap: 19px;
  height: 52px;
  padding-left: 1.3rem;
}
div.data_save_area_1 ul li img {
  width: 23px;
}
div.data_save_area_1 ul li span {
  font-size: 16px;
}
div.data_save_area_1 div.text_box_big {
  background-color: #f7f7f7;
  border-radius: 4px;
  margin-top: 1rem;
}
div.data_save_area_1 div.text_box_big h1 {
  text-align: right;
  font-size: 32px;
  color: #4587f5;
  padding-right: 2rem;
}
div.data_save_area_1 div.text_box_big h1 span {
  color: #111;
  font-size: 32px;
}
div.data_save_area_1 button.submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 295px;
  top: 32px;
  background: #4587f5;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 3rem;
  color: #fff;
}
div.data_save_area_1 button.submit_button:hover {
}
div.data_save_box h2 span:nth-of-type(2) {
  color: #111;
}
div.data_save_area_1 button.submit_button:active {
}
div.data_save_area_1 button.submit_button:focus {
}
.data_save_2_wrapper {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
div.data_save_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  max-width: 500px;
  height: 229px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  /* Inside Auto Layout */
  margin: 0px 10px;
  overflow: hidden;
  position: relative;
  margin: 0;
  margin-top: 2rem;
}
div.data_save_box > h1 {
  font-size: 16px;
  color: #fff;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4587f5;
}
div.data_save_box ul {
  width: 100%;
  padding: 0 24px;
}
div.data_save_box ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}
div.data_save_box ul li h1 {
  font-size: 14px;
}
div.data_save_box ul li span {
  font-size: 14px;
  color: #888888;
}
div.data_save_box h2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 24px;
  right: 24px;
}
div.data_save_box h2 span {
  color: #4587f5;
  font-size: 16px;
}

.data_save_footer {
  width: 100%;
  background: #f7f7f7;
  padding: 50px 20px;
}
.data_save_footer h1 {
  font-size: 14px;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
  font-weight: 500;
}
.data_save_footer span {
  font-size: 14px;
  line-height: 26px;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  color: #666666;
}

.now_subs_mark {
  width: 44px;
  height: 24px;
  position: absolute;
  right: 1rem;
  font-size: 10px;
  background: #ff5555;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data_save_box_subs {
  height: 270px !important;
}

.stop_subs {
  background: #ff5555 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 80px;
  top: 32px;
  border-radius: 1000px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
  margin-top: 3rem;
}

div.data_save_subs_wrapper {
  max-width: 432px;
  margin: 0 auto;
  width: 100%;
  margin-top: 3rem;
}

div.data_save_subs_wrapper div.data_save_subs_row_type_2 {
  margin-top: 10px;
}
div.data_save_subs_wrapper div.data_save_subs_row_type_2 h1 {
  color: #bbbbbb;
  font-size: 12px;
}
div.data_save_subs_wrapper div.data_save_subs_row_type_2 input {
  position: static;
  width: 160px;
  height: 44px;
  left: 0px;
  top: 0px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  padding-left: 1rem;
  margin-top: 6px;
}
div.data_save_subs_wrapper div.data_save_subs_row_type_3 {
  margin-top: 13px;
}
div.data_save_subs_wrapper div.data_save_subs_row_type_3 h1 {
  color: #bbbbbb;
  font-size: 12px;
}
div.data_save_subs_wrapper div.data_save_subs_row_type_3 input {
  position: static;
  width: 80px;
  height: 44px;
  left: 0px;
  top: 0px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 6px;
}
div.data_save_subs_wrapper div.data_save_subs_row_type_3 span {
  vertical-align: middle;
}
div.data_save_subs_wrapper div.added_info_row {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}
div.data_save_subs_wrapper div.added_info_row h1 {
  font-size: 14px;
  font-weight: 500;
}
div.data_save_subs_wrapper div.added_info_row ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  height: 36px;
  border-radius: 8px;
  padding: 2px;
  width: 130px;
}
div.data_save_subs_wrapper div.added_info_row ul li.active {
  height: 100%;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111111 !important;
  width: 50%;
}
div.data_save_subs_wrapper div.added_info_row ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  height: 36px;
  border-radius: 8px;
  padding: 2px;
  font-size: 12px;
  width: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
div.data_save_subs_wrapper div.data_save_subs_row {
}
div.data_save_subs_wrapper div.data_save_subs_row > h1 {
  color: #bbbbbb;
  font-size: 12px;
}
div.data_save_subs_wrapper div.data_save_subs_row input {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
}
div.data_save_subs_wrapper button.payment_submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 152px;
  top: 32px;
  background: #4587f5;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
  margin-top: 44px;
}
div.data_save_subs_wrapper button.payment_submit_button:hover {
}
div.data_save_subs_wrapper button.payment_submit_button:active {
}
div.data_save_subs_wrapper button.payment_submit_button:focus {
}
div.teamword_list {
  display: flex;
  width: 100%;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
  align-items: center;
  justify-content: center;
}
div.teamword_list ul {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: flex-start;
  gap: 22px;
  padding: 12px 0;
  overflow-x: auto;
}
div.teamword_list ul:nth-of-type(1) {
  display: none;
}
div.teamword_list ul li.all {
}
div.teamword_list ul li.all p {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  background: #4587f5;
}
div.teamword_list ul li.all span {
  font-size: 10px;
}

div.teamword_list ul li.active span {
  font-weight: 500;
}

div.teamword_list ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  opacity: 0.5;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90px;
  min-width: 90px;
}
div.teamword_list ul li.active {
  opacity: 1 !important;
}
div.teamword_list ul li p {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-position: center !important;
  background-size: cover !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 100%;
  border: 1px solid #eaeaea;
}
div.teamword_list ul li:nth-of-type(1) p {
}
div.teamword_list ul li span {
  font-size: 10px;
  height: 13px;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
div.photo_wrapper {
  padding-bottom: 3rem;
}
div.photo_wrapper div.photo_row {
  max-width: 700px;
  margin: 0 auto;
}
div.photo_wrapper div.photo_row span.created_at_photo {
  font-size: 12px;
  color: #bbbbbb;
  padding: 1.5rem 0;
  display: flex;
}
div.photo_wrapper div.photo_row ul {
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 16px;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  gap: 1px;
  margin: 0 auto;
}
div.photo_wrapper div.photo_row ul li {
  width: calc(25% - 1px);
  height: calc(700px / 4);
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}
div.photo_wrapper div.photo_row ul li video {
  width: 100%;
  height: 100%;
  background-color: #000000;
}
div.file_list_wrapper {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 3rem;
}
div.file_list_wrapper div.list_file_row {
}
div.file_list_wrapper div.list_file_row > h1 {
  font-size: 12px;
  color: #bbbbbb;
  padding: 1.5rem 0;
  display: flex;
}
div.file_list_wrapper div.list_file_row ul {
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 16 px;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  gap: 8px;
  margin: 0 auto;
}
div.file_list_wrapper div.list_file_row ul li {
  width: calc(25% - 8px);
  height: calc(700px / 4);
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  overflow: hidden;
}
div.file_list_wrapper div.list_file_row ul li > p {
  border: 1px solid #dddddd;
  min-height: 36px;
  border-radius: 100%;
  min-width: 36px;
  width: 36px;
  height: 36px;
  background: url(../images/icon_file.svg) no-repeat;
  background-position: center;
}
div.file_list_wrapper div.list_file_row ul p.excel {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: url(../images/icon_excel.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

div.file_list_wrapper div.list_file_row ul p.xlxs {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: url(../images/icon_excel.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

div.file_list_wrapper div.list_file_row ul p.adobe {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: url(../images/icon_adobe.svg) no-repeat;
  background-size: contain;
  background-position: center;
}
div.file_list_wrapper div.list_file_row ul p.ppt {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: url(../images/icon_ppt.svg) no-repeat;
  background-size: contain;
  background-position: center;
}
div.file_list_wrapper div.list_file_row ul p.word {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: url(../images/icon_ppt.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

div.file_list_wrapper div.list_file_row ul div.file_text_row h1 {
  font-size: 12px;
  color: #111;
  margin-top: 12px;
  height: 40px;
  overflow: hidden;
}
div.file_list_wrapper div.list_file_row ul div.file_text_row span {
  font-size: 12px;
  color: #888888;
}
div.file_list_wrapper div.list_file_row ul h3.due_date {
  font-size: 12px;
  color: #bbbbbb;
  position: static;
  bottom: 12px;
}
div.date_filter_row {
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem 0;
  margin: 0 auto;
  width: 100%;
}
div.date_filter_row h1 {
  font-size: 12px;
  padding-right: 21px;
  background: url(../images/icon_select_bottom.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.date_arr_list_wrapper {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 3rem;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row {
  display: flex;
  flex-direction: column;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row > h1 {
  font-size: 12px;
  color: #bbbbbb;
  padding: 1.5rem 0;
  display: flex;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul {
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  gap: 8px;
  margin: 0 auto;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.request {
  width: calc(25% - 8px);
  height: calc(700px / 4);
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  background: #4587f5;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li {
  width: calc(25% - 8px);
  height: calc(700px / 4);
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  display: none;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li span {
  color: #fff;
  font-size: 13px;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  li
  > h1:nth-of-type(1) {
  display: inline-block;
  width: 50%;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  li
  > span:nth-of-type(1) {
  display: inline-block;
  width: 50%;
  text-align: right;
}

div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.active {
  display: block;
}

div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.admit {
  background: #00cc66;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.complete {
  background: #aabbcc;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.cancel {
  background: #ff5555;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.stay {
  background: #ffcc00;
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul h1 {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row
  span {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at
  br {
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul div.admit_title_row {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.admit_title_row
  h1 {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.admit_title_row
  span {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row
  span {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at
  br {
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul div.admit_title_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.admit_title_row
  h1 {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.admit_title_row
  span {
  font-size: 12px;
  color: #ffffff80;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row
  span {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at
  br {
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul h1 {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row {
  margin-top: 12px;
  font-size: 0;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row
  span {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at
  br {
}
div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul h1 {
  font-size: 14px;
  color: #fff;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row {
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row
  span:nth-of-type(1) {
  font-size: 12px;
  color: #ffffff;
  display: block;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at {
  position: absolute;
  color: #ffffff70;
  left: 12px;
  font-size: 12px;
  bottom: 12px;
  line-height: 1.3;
  display: flex;
}
div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  span.data_arr_created_at
  br {
}

div.date_arr_list_wrapper
  div.date_arr_list_wrapper_row
  ul
  div.date_arr_text_row
  span:nth-of-type(2) {
  color: #ffffff80;
}

.date_price_sub_notice {
  font-size: 12px;
  color: #8888;
  line-height: 16px;
}
div.payment_history_wrapper {
  margin-top: 66px;
}
div.payment_history_wrapper ul.payment_history_list {
  border-top: 1px solid #dddddd;
  width: 100%;
}
div.payment_history_wrapper ul.payment_history_list li {
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}
div.payment_history_wrapper ul.payment_history_list li h1 {
  font-size: 14px;
}
div.payment_history_wrapper ul.payment_history_list li span {
  font-size: 12px;
  color: #888888;
}
div.data_wrapper {
}
div.data_wrapper div.payment_detail_wrapper {
}
div.data_wrapper div.payment_detail_wrapper > h1 {
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 56px;
}

div.data_wrapper div.payment_detail_wrapper ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}
div.data_wrapper div.payment_detail_wrapper ul h1 {
  font-size: 14px;
}
div.data_wrapper div.payment_detail_wrapper ul span {
  font-size: 14px;
  color: #888888;
}
div.data_wrapper div.payment_detail_wrapper_2 {
  margin-top: 4rem;
  padding-bottom: 40px;
  border-bottom: 1px solid #dddddd;
}
div.data_wrapper div.payment_detail_wrapper_2 > h1 {
  font-size: 24px;
  height: 52px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
div.data_wrapper div.payment_detail_wrapper_2 ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 1.5rem;
  flex-direction: column;
}
div.data_wrapper div.payment_detail_wrapper_2 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 100%;
}
div.data_wrapper div.payment_detail_wrapper_2 ul h1 {
  font-size: 14px;
}
div.data_wrapper div.payment_detail_wrapper_2 ul span {
  color: #888888;
  font-size: 14px;
}

div.data_wrapper div.payment_detail_wrapper_2 ul.button_wrapper_payment li {
  width: 100%;
}
div.data_wrapper div.payment_detail_wrapper_2 ul.button_wrapper_payment button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: #f2f2f2;
}
div.top.search_module {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  display: none;
  padding-left: 16px;
}
div.top.search_module div.left {
  position: static !important;
  width: calc(100% - 40px);
}
div.top.search_module div.left div.search_input_wrapper {
  background: #f2f2f2;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
}
div.top.search_module div.left div.search_input_wrapper div.inner_col {
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
}
div.top.search_module div.left div.search_input_wrapper p.del_text {
  width: 16px;
  height: 16px;
  background: url(../images/icon_close_s_w.svg) no-repeat;
  background-color: #bbbbbb;
  border-radius: 100%;
  background-position: center;
  cursor: pointer;
}
div.top.search_module div.left div.search_input_wrapper img {
}
div.top.search_module div.left div.search_input_wrapper input#search_input {
  width: 100%;
}
div.top.search_module span {
  min-width: 41px;
  cursor: pointer;
  font-size: 14px;
  padding-left: 2px;
  text-align: center;
}
div.status_select_popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  display: none;
}
div.status_select_popup div.status_select_popup_box {
  background: #fff;
  position: relative;
  z-index: 6;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
  border-radius: 16px;
  padding: 2rem;
  min-width: 300px;
}
div.status_select_popup div.status_select_popup_box h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
}
div.status_select_popup div.status_select_popup_box > ul:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.status_select_popup div.status_select_popup_box ul li.active {
  background: url(../images/icon_checked_active.svg) no-repeat !important;
  background-position: right !important;
}
div.status_select_popup div.status_select_popup_box ul:nth-of-type(1) li {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  font-size: 12px;
  background: url(../images/icon_check.svg) no-repeat;
  background-position: right;
  cursor: pointer;
}
div.status_select_popup
  div.status_select_popup_box
  ul.status_select_popup_button_list {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2rem;
}
div.status_select_popup
  div.status_select_popup_box
  ul.status_select_popup_button_list
  li {
  font-size: 13px;
  cursor: pointer;
}

div.status_select_popup
  div.status_select_popup_box
  ul.status_select_popup_button_list
  li {
  color: #4587f5;
}
.cal_all div.pc_right_area_top {
  width: 100% !important;
}
.cal_all .fc .fc-toolbar.fc-header-toolbar {
  padding: 0;
}
div.status_select_popup div.status_select_popup_background_black {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #00000050;
}
ul.search_result_list.data_list {
}
ul.search_result_list.data_list li.active {
  align-items: flex-start;
  padding-bottom: 12px;
}
ul.search_result_list.data_list li.active p.type_1 {
}
ul.search_result_list.data_list li.active div.data_list_textArea {
}
ul.search_result_list.data_list li.active div.data_list_textArea span {
}
ul.search_result_list.data_list li.active div.data_list_textArea h1 {
  display: flex;
}
ul.search_result_list.data_list
  li.active
  div.data_list_textArea
  span.date_created_at {
}
ul.search_result_list.data_list
  li.active
  div.data_list_textArea
  span.data_price_detail {
}

.sideMenuFooter {
  padding: 44px 0 32px;
}
.sideMenuFooterTitle {
  font-size: 14px;
  font-weight: 700;
  color: #bbb;
  margin: 0 0 6px;
}
.sideMenuFooterText {
  color: #bbb;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 767px) {
  div.data_save_subs_wrapper {
    padding: 0 20px;
  }
  div.date_arr_list_wrapper
    div.date_arr_list_wrapper_row
    ul
    span.data_arr_created_at {
  }
  div.pc_right_area div.pc_right_area_top .right {
    /* right: 0 !important; */
  }
  div.pc_right_area div.right_area_my_info,
  div.edit_wrapper,
  div.submit_b_wrapper {
    padding: 0 16px;
  }
  div.pc_right_area div.pc_right_area_top div.left {
    left: 20px;
  }
  div.pc_right_area div.pc_right_area_top,
  div.data_save_box {
    /* width: calc(100% - 32px); */
    margin: 0 auto;
  }
  div.pc_right_area div.pc_right_area_top.notice_right_area {
    width: 100%;
  }
  div.data_save_box {
    margin-top: 1rem;
  }
  .data_save_footer {
    padding: 20px 16px 80px;
  }
  div.agree_wrapper {
    padding-bottom: 3rem;
  }
  .morePage div.pc_right_area {
    display: block;
    z-index: 99;
    padding: 0;
  }
  .morePage .file_sub_text {
    padding-left: 16px;
  }

  .change_pass_wrapper .user_wrapper > div.input_wrapper {
    padding: 0 16px;
  }
  .morePage .b_submit_file_list_board {
    padding: 0 16px;
  }
  div.pc_right_area div.pc_right_area_top div.left {
  }
  div.file_list_wrapper div.list_file_row ul h3.due_date {
    position: static;
  }
  div.pc_right_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
    padding-bottom: 5rem;
    padding-left: 400px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 9;
    background: #fff;
    padding-left: 0;
    padding: 0 16px;
    display: none;
    overflow: auto;
  }

  div.payment_history_wrapper ul.payment_history_list {
    padding-bottom: 120px;
  }
}
