/* 2022-02-24 인수인계
gnb css입니다. */

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header .left {
    position: absolute;
    left: 20px;
}
.header .center {
}
.header .right {
}
.header .left img {
    cursor: pointer;
}
.header .right img {
}

.header .center img {
    max-width: 100px;
}
