/* 2022-02-24 인수인계
페이지 타이틀, 좌측 gnb가 없는 페이지의 전체 wrapper에 적용되는 css 입니다. */

.page_title {
    font-weight: 500;
    text-align: center;
    font-size: 40px;
    margin-bottom: 100px;
}

.user_wrapper {
    width: 100%;
    padding-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    div.user_wrapper {
        margin-top: 6rem;
    }
    div.user_wrapper > h1 {
        font-size: 24px;
        margin-bottom: 50px;
    }
}
