/* 2022-02-24 인수인계
설정 페이지 css입니다. */

div.pc_right_area_top.pc_right_area_top_white {
    background: #ffffff;
}
div.pc_right_area_top.pc_right_area_top_white div.left {
}
div.pc_right_area_top.pc_right_area_top_white div.left img {
}
div.pc_right_area_top.pc_right_area_top_white h1.right_area_title {
}
div.setting_gray_wrapper {
    width: 100%;
    height: calc(100vh - 60px);
    background: #f7f7f7;
    padding: 15px 0 0;
}
div.setting_gray_wrapper div.setting_contents_wrapper {
    max-width: 750px;
    height: auto;
    background: #fff;
    margin: 0 auto;
    margin-top: 24px;
}
div.setting_gray_wrapper div.setting_contents_wrapper ul {
}
div.setting_gray_wrapper div.setting_contents_wrapper ul li {
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    border-bottom: 1px solid #f2f2f2;
    background: url(../images/icon_right_s.svg) no-repeat;
    background-position: calc(100% - 18px);
}
ul.alarm_setting_list li.timer select {
    color: #888888;
    min-width: 85px;
}
div.setting_gray_wrapper div.setting_contents_wrapper ul a {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
div.setting_gray_wrapper div.setting_contents_wrapper ul a:hover {
}
div.setting_gray_wrapper div.setting_contents_wrapper ul a:active {
}
div.setting_gray_wrapper div.setting_contents_wrapper ul a:focus {
}
div.exit_notice_wrapper {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;
}
div.exit_notice_wrapper div.exit_notice_row {
    padding-bottom: 44px;
    border-bottom: 1px solid #dddddd;
}
div.exit_notice_wrapper div.exit_notice_row h1 {
}
div.exit_notice_wrapper div.exit_notice_row span {
}
div.exit_notice_wrapper div.exit_notice_row h1 {
    font-size: 32px;
    font-weight: 500;
}
div.exit_notice_wrapper div.exit_notice_row span {
    font-size: 16px;
    line-height: 28px;
    color: #888888;
    margin-top: 10px;
    display: flex;
}
div.exit_notice_wrapper button.exit_excute_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: calc(100% - 32px);
    height: 44px;
    left: 16px;
    top: 32px;
    background: #f2f2f2;
    border-radius: 8px;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
    margin: 0 auto;
    color: #ff5555;
}
div.exit_notice_wrapper button.exit_excute_button:hover {
}
div.exit_notice_wrapper button.exit_excute_button:active {
}
div.exit_notice_wrapper button.exit_excute_button:focus {
}

div.exit_notice_wrapper div.exit_notice_row:nth-of-type(1) {
    margin-bottom: 32px;
}

div.exit_notice_wrapper div.exit_notice_row:nth-of-type(2) {
    border-bottom: 0;
}
ul.alarm_setting_list {
}
ul.alarm_setting_list li {
    justify-content: space-between;
    padding-right: 18px;
}
ul.alarm_setting_list li span {
    font-size: 14px;
}
ul.alarm_setting_list li div.common_toggle_button {
    width: 40px;
    height: 24px;
    left: 0px;
    top: 0px;
    background: #dbdbdb;
    border-radius: 1000px;
    position: relative;
    cursor: pointer;
}
ul.alarm_setting_list li div.common_toggle_button p.active {
}
ul.alarm_setting_list li div.common_toggle_button p {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: 2px;
    background: #ffffff;
    border-radius: 1000px;
}

ul.alarm_setting_list li div.common_toggle_button.active p {
    left: 18px;
}

ul.alarm_setting_list li div.common_toggle_button.active {
    background: #4587f5;
}

ul.alarm_setting_list li.timer {
    background: #f2f2f2 !important;
}
ul.alarm_setting_list li.timer span {
    font-size: 14px;
}
ul.alarm_setting_list li.timer h1 {
    font-size: 14px;
    padding-right: 1.25rem;
    color: #888888;
    background: url(../images/icon_select_bottom.svg) no-repeat;
    background-position: right;
    cursor: pointer;
}
ul.notice_list {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
}
ul.notice_list li {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-left: 16px;
    height: 84px;
    justify-content: center;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
}
ul.notice_list li h1 {
    font-size: 14px;
    font-weight: 500;
}
ul.notice_list li span {
    font-size: 12px;
    color: #888888;
    font-weight: 300;
}
div.notice_detail_wrapper {
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 4rem;
    width: 100%;
}
div.notice_detail_wrapper div.title_area {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dddddd;
}
div.notice_detail_wrapper div.title_area h1 {
    font-size: 32px;
    font-weight: 500;
}
div.notice_detail_wrapper div.title_area span {
    font-size: 12px;
    color: #888888;
}
div.notice_detail_wrapper div.notice_contents {
    padding-top: 24px;
}
div.notice_detail_wrapper div.notice_contents img {
    max-width: 100%;
    border-radius: 16px;
    margin-top: 5px;
}
div.notice_detail_wrapper div.notice_contents span {
    font-size: 16px;
    color: #666666;
    line-height: 28px;
    margin-top: 12px;
    display: flex;
}
ul.button_type_sq_select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f2f2;
    min-height: 36px;
    border-radius: 8px;
    padding: 2px;
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 3rem;
}
ul.button_type_sq_select li.active {
    height: 100%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #111111 !important;
    min-height: 36px;
}
ul.button_type_sq_select li {
    width: 50%;
    text-align: center;
    font-size: 12px;
    color: #bbbbbb;
    cursor: pointer;
}
ul.button_type_sq_select li a {
    display: flex;

    align-items: center;

    justify-content: center;

    width: 100%;

    height: 100%;

    height: 36px;
}
div.input_wrapper {
}
div.input_wrapper textarea {
    position: static;
    width: 100%;
    height: 88px;
    left: 16px;
    top: 25px;
    background: #f2f2f2;
    border-radius: 8px;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 6px 0px;
    resize: none;
    padding: 1rem;
}
div.input_wrapper h1 {
    font-size: 12px;
    color: #bbbbbb;
}
div.input_wrapper select {
    position: static;
    width: 100%;
    height: 44px;
    left: 16px;
    top: 25px;
    background: #f2f2f2;
    border-radius: 8px;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 6px 0px;
    padding-left: 1rem;
}
div.input_wrapper select option {
}
ul.b_submit_file_list {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
    flex-direction: row;
    margin-top: 2rem;
    justify-content: flex-start;
    max-width: 440px;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
}
ul.b_submit_file_list li.added_file {
    width: 92px;
    height: 92px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    position: relative;
    font-size: 0;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    background-size: cover !important;
    background-position: center !important;
}
ul.b_submit_file_list li.added_file p.file_del_button {
    width: 16px;
    height: 16px;
    background: url(../images/icon_close_s_w.svg) no-repeat;
    background-color: #bbbbbb;
    border-radius: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-position: center;
    cursor: pointer;
}
ul.b_submit_file_list li.added_file div.file_icon {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid #dddddd;
    background: url(../images/icon_file.svg) no-repeat;
    background-position: center;
    margin-top: 8px;
}
ul.b_submit_file_list li.added_file span.file_name {
    font-size: 10px;
    display: block;
    margin-top: 10px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 16px;
}
ul.b_submit_file_list li.added_file span.file_size {
    font-size: 10px;
    color: #bbbbbb;
}
ul.b_submit_file_list li.file_add_button {
    width: 92px;
    height: 92px;
    border-radius: 8px;
    background: url(../images/icon_plus_b.svg) no-repeat;
    background-position: center;
    background-color: #f2f2f2;
    cursor: pointer;
}
ul.b_submit_file_list li.file_add_button label {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
}
ul.b_submit_file_list li.file_add_button label input {
    display: none;
}
button.faq_submit_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 160px;
    height: 44px;
    left: 152px;
    top: 32px;
    background: #4587f5;
    border-radius: 1000px;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
    color: #fff;
    margin: 0 auto;
    font-size: 14px;
    margin-top: 3rem;
}
button.faq_submit_button:hover {
}
button.faq_submit_button:active {
}
button.faq_submit_button:focus {
}

.file_sub_text {
    max-width: 440px;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    font-size: 11px;
    padding-bottom: 12px;
    color: #bbbbbb;
}
ul.faq_list {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    margin-top: 24px;
}
ul.faq_list li {
    display: flex;
    flex-direction: row;
    gap: 3px;
    padding-left: 16px;
    height: 84px;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
    align-items: center;
    padding-right: 16px;
}
ul.faq_list li p.faq_type_2 {
}
ul.faq_list li div.left_text_list {
}
ul.faq_list li div.left_text_list h1 {
}
ul.faq_list li div.left_text_list span {
}
ul.faq_list li div.left_text_list h1 {
    font-size: 14px;
    font-weight: 500;
}
ul.faq_list li div.left_text_list span {
    font-size: 12px;
    color: #888888;
    font-weight: 300;
}
ul.faq_list li p.faq_type_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 12px;
    position: static;
    width: auto;
    height: 32px;
    left: 0px;
    top: 0px;
    background: #4587f5;
    border-radius: 8px;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    font-size: 14px;
    color: #fff;
}

.faq_type_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 12px;
    position: static;
    width: auto;
    height: 32px;
    left: 0px;
    top: 0px;
    background: #f2f2f2;
    border-radius: 8px;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    font-size: 14px;
    color: #fff;
    color: #c1c1c1;
}
div.faq_response {
    padding-left: 20%;
    margin-top: 3rem;
}
div.faq_response h1.faq_sub_title {
}
div.faq_response img {
    max-width: 100%;
    border-radius: 16px;
}
div.faq_response span {
    font-size: 16px;
    color: #666666;
    line-height: 28px;
    margin-top: 12px;
    display: flex;
}

.faq_sub_title {
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    div.file_list_wrapper {
        padding: 3rem 20px;
        padding-top: 0rem !important;
    }
    div.file_list_wrapper div.list_file_row ul {
        justify-content: flex-start;
    }
    div.file_list_wrapper div.list_file_row ul li {
        width: calc(50% - 4px);
        height: auto;
        background-size: cover !important;
        background-position: center !important;
        cursor: pointer;
    }
    div.file_list_wrapper {
        padding: 3rem 20px;
        padding-top: 0rem !important;
    }
    div.file_list_wrapper div.list_file_row ul {
        justify-content: flex-start;
    }
    div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li,
    div.date_arr_list_wrapper div.date_arr_list_wrapper_row ul li.request {
        width: calc(50% - 4px);
        height: calc(600px / 3.3);
        background-size: cover !important;
        background-position: center !important;
        cursor: pointer;
    }
    div.teamword_list ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 12px 20px;
        overflow-x: auto;
        width: 100%;
    }
    div.date_filter_row {
        padding-right: 16px;
        padding-bottom: 0;
    }
    div.date_arr_list_wrapper {
        max-width: 700px;
        margin: 0 auto;
        width: 100%;
        padding: 0 16px;
        padding-bottom: 5rem;
    }
    div.search_header div.info_search_wrapper {
        margin-left: 0;
    }
    div.search_header {
        padding-top: 10px;
        padding: 10px 10px;
    }
    div.teamword_list ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 12px 20px;
        overflow-x: auto;
        width: 100%;
    }
    div.data_wrapper {
        padding: 0 16px;
        padding-bottom: 5rem;
    }
    div.photo_wrapper {
        padding: 3rem 20px;
    }

    div.photo_wrapper div.photo_row ul li {
        width: calc(33.333% - 1px);
        height: calc(510px / 4);
        background: url(../images/sample_img_1.png) no-repeat;
        background-size: cover !important;
        background-position: center !important;
        cursor: pointer;
    }
    div.teamword_list ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 12px 20px;
        overflow-x: auto;
        width: 100%;
    }

    div.data_wrapper > div.data_select_row > div.send_data > span {
        font-size: 12px;
    }
    div.data_wrapper > div.data_select_row > div.send_data {
        width: 27%;
    }
    div.data_wrapper > div.data_select_row {
        gap: 0;
    }
    div.data_wrapper > div.data_select_row {
        justify-content: space-between;
    }
    div.data_wrapper > div.data_price_row > h1 > span {
        font-size: 24px;
    }
    div.data_wrapper > div.data_price_row > h1 {
        padding: 2rem 0;
        display: flex;
        align-items: center;
        gap: 6px;
    }
    div.notice_detail_wrapper {
        padding: 0 16px;
    }
    div.notice_detail_wrapper div.title_area h1 {
        font-size: 20px;
    }
    div.notice_detail_wrapper {
        margin-top: 1rem;
    }
    div.exit_notice_wrapper div.exit_notice_row h1 {
        font-size: 20px;
    }
    div.exit_notice_wrapper div.exit_notice_row span {
        font-size: 14px;
    }
    div.exit_notice_wrapper div.exit_notice_row {
        padding: 0 16px;
        padding-bottom: 30px;
    }
    .header .left {
        left: 16px;
    }
    .header .center {
        font-size: 14px;
    }
}
