/* 2022-02-24 인수인계
input ui css 입니다. */

div.input_wrapper {
    width: calc(100% - 32px);
    margin: 0 auto;
    max-width: 440px;
}
div.input_wrapper div.input_row {
}
div.input_wrapper div.input_row h1 {
    font-size: 12px;
    color: #bbbbbb;
}
div.input_wrapper div.input_row input {
    width: 100%;
    height: 44px;
    background: #f2f2f2;
    border-radius: 8px;
    padding-left: 1rem;
}

div.input_wrapper div.input_row input::placeholder {
    color: #bbbbbb;
}

.input_outter {
    margin-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
}

.red {
    color: #ff5555;
}

.input_outter p {
    font-size: 14px;
    position: absolute;
    right: 0;
    z-index: 6;
    right: 16px;
    cursor: pointer;
}

#count-down-timer {
    display: none;
}
.sub_alert {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-top: 5px;
    display: none;
}

.blue {
    color: #4587f5 !important;
}

.mt_15 {
    margin-top: 15px;
}

.page_desc {
    max-width: 440px;
    width: calc(100% - 32px);
}
.page_desc h2 {
    font-size: 20px;
    font-weight: 500;
}
.page_desc span {
    font-size: 12px;
    color: #888888;
    font-weight: 300;
}

.found_id {
    font-size: 24px;
    margin: 80px 0;
    text-align: center;
    word-break: keep-all;
}
