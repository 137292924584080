/* 2022-02-24 인수인계
프로필 모듈 css입니다. (멤버 클릭시 나타나는 팝업) */

div.view_profile_module {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
div.view_profile_module div.view_my_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 330px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    z-index: 6;
    display: none;
}
div.view_profile_module div.view_my_profile p.edit_button {
}
div.view_profile_module div.view_my_profile div.view_profile_inner {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 36px;
}
div.view_profile_module div.view_my_profile div.view_profile_inner p {
    width: 66px;
    height: 66px;
    border-radius: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
    border: 1px solid #eaeaea;
    background: url("../images/icon_profile_basic.svg");
}
div.view_profile_module div.view_my_profile div.view_profile_inner h1 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 14px;
}

div.view_profile_module div.view_my_profile div.view_profile_inner > span {
    font-size: 12px;
    color: #888888;
    text-align: center;
    margin-top: 6px;
    padding: 0 30px;
    word-break: break-all;
}
div.view_profile_module div.view_my_profile > ul.view_profile_sub_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 73px;
    width: 100%;
    padding-bottom: 24px;
    padding-top: 20px;
}
div.view_profile_module div.view_my_profile ul li {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 9px;
}
div.view_profile_module div.view_my_profile ul p {
    width: 52px;
    height: 52px;
    border-radius: 100%;
    background-position: center !important;
    background-size: cover !important;
}
div.view_profile_module div.view_my_profile ul span {
    font-size: 10px;
}
div.view_profile_module div.background_black {
}

div.view_profile_module div.view_my_profile ul li:nth-of-type(1) p {
    background: url(../images/icon_profile_chat_1.svg);
}
div.view_profile_module div.view_my_profile ul li:nth-of-type(2) p {
    background: url(../images/icon_profile_chat_2.svg);
}

.view_my_profile_edit_button {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    cursor: pointer;
}

.view_profile_inner p b {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #4587f5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 17px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

div.view_my_profile.view_member_profile {
}
div.view_my_profile.view_member_profile img.view_my_profile_more_button {
    position: absolute;
    right: 2rem;
    top: 0.5rem;
    cursor: pointer;
    padding: 1rem 0;
}
.report_sub_popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 12px;
    position: absolute;
    width: 140px;
    height: 68px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    right: 1rem;
    top: 2.6rem;
    z-index: 9;
    display: flex;
    justify-content: center;
    gap: 6px;
    display: none;
    padding-top: 14px !important;
    width: auto !important;
    padding: 14px 35px !important;
}
.report_sub_popup li {
    font-size: 12px;
}
div.view_my_profile.view_member_profile div.view_profile_inner {
}
.report_sub_popup li:nth-of-type(2) {
    color: #ff5555;
}
div.view_my_profile.view_member_profile div.view_profile_inner p {
}
div.view_my_profile.view_member_profile div.view_profile_inner p b {
    cursor: pointer;
}
div.view_my_profile.view_member_profile div.view_profile_inner h1 {
}
div.view_my_profile.view_member_profile div.view_profile_inner span {
}
div.view_my_profile.view_member_profile div.view_profile_inner span span {
    cursor: pointer;
}
div.view_my_profile.view_member_profile ul {
}
div.view_my_profile.view_member_profile ul li {
}
div.view_my_profile.view_member_profile ul li p {
}
div.view_my_profile.view_member_profile ul li:nth-of-type(2) p {
    background: url(../images/icon_do_call.svg) no-repeat !important;
}
div.view_my_profile.view_member_profile ul li span {
}
.view_profile_inner h5 {
    background: #333333;
    font-size: 13px;
    color: #fff;
    padding: 0.7rem 0.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    position: absolute;
    top: 110px;
}
