/* 2022-02-24 인수인계
로그인시 처음 노출되는 멤버페이지 css입니다. */

div.memeber_detail_wrapper {
    margin-top: 24px;
}
div.memeber_detail_wrapper div.member_detail_info_top {
    display: flex;
    align-items: center;
    justify-content: center;
}
div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
    border: 1px solid #eaeaea;
}
div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p.mark_type_1 {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: url(../images/icon_master_2.svg) no-repeat;
    background-position: center;
    right: 0;
    bottom: 0;
    background-size: cover;
}
div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p.mark_type_2 {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: url(../images/icon_master_1.svg) no-repeat;
    background-position: center;
    right: 0;
    bottom: 0;
    background-size: cover;
}
div.memeber_detail_wrapper ul.member_detail_menu_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 2rem;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.create_new_teamwork {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.create_new_teamwork p {
    width: 52px;
    height: 52px;
    border-radius: 100%;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li {
    min-width: 160px;
    cursor: pointer;
}

div.memeber_detail_wrapper ul.member_detail_menu_list li.create_new_teamwork:nth-of-type(1) p {
    background: url(../images/icon_create_chat.svg) no-repeat;
    background-position: center;
    background-size: cover;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.create_new_teamwork:nth-of-type(2) p {
    background: url(../images/icon_create_chat.svg) no-repeat;
    background-position: center;
    background-size: cover;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.create_new_teamwork span {
    font-size: 10px;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call a {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 7px;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call p {
    background: url(../images/icon_do_call.svg) no-repeat;
    background-position: center;
    background-size: cover;
    width: 52px;
    height: 52px;
    border-radius: 100%;
}
div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call span {
    font-size: 10px;
}
div.memeber_detail_wrapper button.remove_permission {
    width: calc(100% - 32px);
    border-radius: 8px;
    background: #f2f2f2;
    font-size: 14px;
    font-weight: 500;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 24px;
}
div.memeber_detail_wrapper button.remove_permission:hover {
}
div.memeber_detail_wrapper button.remove_permission:active {
}
div.memeber_detail_wrapper button.remove_permission:focus {
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper {
    border-top: 1px solid #dddddd;
    padding-top: 1rem;
    margin-top: 20px;
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li {
    height: 52px;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul a {
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url(../images/icon_right_s.svg) no-repeat;
    background-position: right;
    font-weight: 500;
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul a:hover {
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul a:active {
}
div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul a:focus {
}

div.memeber_price_wrapper {
}
div.memeber_price_wrapper div.date_picker_row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1rem 0;
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
}
div.memeber_price_wrapper div.date_picker_row img {
    filter: grayscale(1);
}
div.memeber_price_wrapper div.date_picker_row span {
    font-size: 12px;
}
div.memeber_price_wrapper div.price_detail_info {
    padding: 0 16px;
    padding-top: 36px;
}
div.memeber_price_wrapper div.price_detail_info > h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;
}
div.memeber_price_wrapper div.price_detail_info h1 span {
}
div.memeber_price_wrapper div.price_detail_info h1 span.blue {
}
div.memeber_price_wrapper div.price_detail_info div.member_price_sort_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid #dddddd;
}
div.memeber_price_wrapper div.price_detail_info div.member_price_sort_row h1 {
    font-size: 12px;
    font-weight: 500;
    padding-right: 1rem;
    background: url(../images/icon_select_bottom.svg) no-repeat;
    background-position: right;
    display: inline;
    cursor: pointer;
}
div.memeber_price_wrapper div.price_detail_info div.member_price_sort_row button {
    font-size: 12px;
    color: #4587f5;
    text-decoration: underline;
}
div.memeber_price_wrapper div.price_detail_info div.member_price_sort_row button:hover {
}
div.memeber_price_wrapper div.price_detail_info div.member_price_sort_row button:active {
}
div.memeber_price_wrapper div.price_detail_info div.member_price_sort_row button:focus {
}
div.memeber_price_wrapper div.memeber_price_list_wrapper {
    margin-top: 1rem;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul li {
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.left {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.left h1 {
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.left span {
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.left h1 {
    font-size: 14px;
    font-weight: 500;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.left span {
    color: #888888;
    font-size: 12px;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.memeber_price_list_right {
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.memeber_price_list_right p.type_1 {
    width: auto;
    height: 32px;
    background: #4587f5;
    color: #fff;
    padding: 0 10px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.memeber_price_wrapper div.memeber_price_list_wrapper ul div.memeber_price_list_right p.type_2 {
    width: auto;
    height: 32px;
    background: #f2f2f2;
    color: #bbbbbb;
    padding: 0 10px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.view_price_wrapper {
}
div.view_price_wrapper ul {
    padding: 0 16px;
}
div.view_price_wrapper ul li.active {
    background: url(../images/icon_checked_active.svg) no-repeat;
    background-position: 100%;
}
div.view_price_wrapper ul li div.left {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
div.view_price_wrapper ul li.active h1 {
}
div.view_price_wrapper ul li.active span {
}
div.view_price_wrapper ul li {
    min-height: 84px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #f2f2f2;
    background: url(../images/icon_check.svg) no-repeat;
    background-position: 100%;
    cursor: pointer;
    padding-right:25px
}

div.view_price_wrapper ul.data_list li > p{
    margin:2px 0 0
}

div.view_price_wrapper ul li div.left {
}
div.view_price_wrapper ul li h1 {
    font-size: 14px;
    font-weight: 500;
}
div.view_price_wrapper ul li span {
    font-size: 12px;
    color: #888888;
}
