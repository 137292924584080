/* 2022-02-24 인수인계
채팅등 여러페이지의 스타일이 혼재된 css파일입니다. */

.wrap {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  align-items: flex-start;
}
div.left_menu_bar {
  width: 60px;
  height: 100vh;
  background: #334455;
  display: flex;
  min-width: 60px;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 9;
}
.video_prev_alert {
  width: 300px;
  height: 160px;
  background-color: #000000;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.payment_submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 160px;
  height: 44px;
  background: #4587f5;
  border-radius: 1000px;
  margin: 8px auto;
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}
div.left_menu_bar ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.left_menu_bar ul li.user_profile {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background: url(../images/icon_profile_basic.svg) no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
div.left_menu_bar ul li {
  margin: 15px 0;
  position: relative;
}

div.left_menu_bar ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

div.left_menu_bar ul li.member a {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.left_menu_bar ul li img {
  filter: invert(53%) sepia(8%) saturate(0%) hue-rotate(131deg) brightness(98%)
    contrast(81%);
}
div.left_menu_bar ul li.active img {
  filter: none;
}
.chat span {
  width: 18px;
  height: 18px;
  background-color: #ff5555;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  font-size: 10px;
  position: absolute;
  right: 2px;
  top: -8px;
}
.un_read_msg_count {
}
.chat_wrapper {
  max-width: 320px;
  width: 100%;
  height: 100vh;
  background: #f7f7f7;
  border-right: 1px solid #dddddd;
  min-width: 320px;
  position: relative;
  position: fixed;
  left: 60px;
  z-index: 9;
}
div.chat_header {
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
}
div.chat_header h1 {
  font-size: 24px;
  font-weight: 500;
}
div.chat_header ul {
  display: flex;
  align-items: center;
  gap: 14px;
}

div.chat_header ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarmListPopup{top: 0;display: none}
.alarmOpen .newAlarm{fill: transparent}
.alarmOpen.active .newAlarm{fill: #FF5555}
.alarmList_section{padding: 13px 8px 40px;height: calc(100% - 52px);overflow-y: auto}
.alarmListItem{padding: 13px 12px;font-size: 14px;font-weight: 500}
.alarmListItem + .alarmListItem{border-top: 1px solid #F2F2F2}
.alarmListUser{font-size: 12px;color: #888;font-weight: 500}
.alarmListText{margin: 4px 0}
.alarmListDate{font-size: 12px;color: #bbb;font-weight: 400}

div.member_list div.my_profile {
  padding: 25px 16px;
  border-bottom: 1px solid #dddddd;
}
div.member_list div.my_profile > span {
  color: #bbb;
  font-size: 12px;
  font-weight: 300;
  margin: 10px 0;
  display: flex;
}
div.profile_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px 0;
}
div.profile_row p.user_profile_img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  min-width: 44px;
  background: url(../images/icon_profile_basic.svg) no-repeat;
  min-height: 44px;
  background-size: cover;
  background-position: center;
}

/* .fc-daygrid-day-events > div {
    display: none;
}
.fc-daygrid-day-events > div:nth-of-type(1),
.fc-daygrid-day-events > div:nth-of-type(2),
.fc-daygrid-day-events > div:nth-of-type(3),
.fc-daygrid-day-events > div:nth-of-type(4),
.fc-daygrid-day-events > div:nth-of-type(5) {
    display: block;
} */

div.profile_row h1 {
  font-weight: 500;
  font-size: 14px;
}
div.profile_row span {
  font-size: 12px;
  color: #888888;
  font-weight: 300;
  word-break: break-all;
}
div.profile_row span span {
  cursor: pointer;
  margin-left: 4px;
}
div.member_list div.user_profile_wrapper {
  padding: 15px 16px;
}

div.member_list div.user_profile_wrapper div.user_profile_state h1 {
  color: #bbb;
  font-size: 12px;
  font-weight: 300;
  margin: 10px 0;
  display: flex;
  gap: 4px;
}

div.member_list
  div.user_profile_wrapper
  div.user_profile_state
  span:nth-of-type(2) {
  color: #4587f5;
}

div.member_list div.user_profile_wrapper div.profile_row {
  padding-bottom: 0;
}

div.member_list div.user_profile_wrapper div.profile_row div.text_col {
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 0;
}

div.chatting_area {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
  padding-left: 380px;
  padding-bottom: 4rem;
}
div.chatting_area div.chatting_header {
  height: 52px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
div.chatting_area div.chatting_header .left {
  position: absolute;
  left: 16px;
}
div.chatting_area div.chatting_header div.left img {
  cursor: pointer;
}
div.chatting_area div.chat_data_area {
  padding-top: 12px;
  position: relative;
  height: calc(100% - 62px);
  /* overflow-y: auto; */
  width: 100%;
  /* display: flex;
    flex-direction: column; */
}
div.chatting_area div.chat_data_area div.line_notice {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
}
div.chatting_area div.chat_data_area div.line_notice span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 300;
  text-align: center;
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 9;
}
div.chatting_area div.chat_write {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 16px;
  justify-content: flex-end;
}

div.chatting_area div.chat_data_area div.line_notice::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #dddddd;
}

div.chatting_area div.chat_write ul.left_button_list {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  gap: 11px;
  margin-right: 11px;
}
div.chatting_area div.chat_write ul.left_button_list li.add {
  height: 100%;
  background: url(../images/icon_add_paper.svg) no-repeat;
  background-position: center;
  min-width: 30px;
  cursor: pointer;
}
div.chatting_area div.chat_write ul.left_button_list li.plus {
  width: 100%;
  height: 100%;
  background: url(../images/icon_plus.svg) no-repeat;
  background-position: center;
  min-width: 30px;
  cursor: pointer;
}
div.chatting_area div.chat_write div.input_wrapper {
  width: 100%;
  min-width: 0;
  height: 36px;
  display: flex;
  align-items: center;
  background: #f2f2f2;
  border-radius: 10px;
}

div.write_input_wrapper {
  width: calc(100% - 460px);
  height: 36px;
  background: #f2f2f2;
  border-radius: 1000px;
  position: relative;
  display: flex;
  align-items: center;
}
div.write_input_wrapper input {
  width: 90%;
  padding-left: 16px;
}
div.write_input_wrapper button.chat_submit {
  width: 30px;
  height: 30px;
  background: url(../images/icon_up.svg) no-repeat;
  background-color: #4587f5;
  border-radius: 100%;
  position: absolute;
  right: 3px;
  top: 3px;
  background-position: center;
  cursor: pointer;
}
div.chat_data_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.right_b_info_wrapper_rejected span {
  font-size: 14px;
}
div.chat_data_inner div.chat_row {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
div.chat_data_inner div.chat_row p {
}

div.chat_list_wrapper {
}
div.chat_list_wrapper ul.chat_list {
  display: flex;
  width: 100%;
  padding-top: 2rem;
  flex-direction: column;
}
.schedule_bottom_box {
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  bottom: -25px;
}
.modi_teamwork_password {
  display: none;
}

.button_change_password {
  display: none;
}
.schedule_bottom_box p {
  color: #bbbbbb;
  font-size: 12px;
}
.schedule_bottom_box span {
  font-size: 12px;
  color: #4587f5;
}
div.chat_list_wrapper ul.chat_list > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  width: calc(100% - 32px);
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  margin-top: 15px;
}
div.chat_list_wrapper ul.chat_list > li > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
  width: 100%;
}
div.chat_list_wrapper ul.chat_list li div.chat_list_col {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}
div.chat_list_wrapper ul.chat_list li span {
  font-size: 10px;
  color: #bbbbbb;
}
div.chat_list_wrapper ul.chat_list li p.chat_msg_count {
  font-size: 10px;
  background: #ff5555;
  border-radius: 100%;
  color: #fff;
  font-weight: 500;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.chat_list_wrapper ul.chat_list li p.chat_img {
  min-width: 44px;
  min-height: 44px;
  width: 44px;
  height: 44px;
  background: #aaa;
  border-radius: 100%;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row div.team_work_title {
  display: flex;
  align-items: center;
  gap: 5px;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row div.chat_thumb_text {
  width: 100%;
}
div.chat_list_wrapper
  ul.chat_list
  li
  div.chat_text_row
  div.chat_thumb_text
  span {
  color: #888888;
  font-size: 12px;
  white-space: nowrap;
  width: 171px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row h1 {
  font-weight: 500;
  font-size: 14px;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row > span {
  font-size: 12px;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row img {
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row span {
  color: #888888;
  font-size: 14px;
}

div.chat_list_wrapper ul.chat_list li div.chat_list_col:nth-of-type(2) {
  flex-direction: column;
  min-width: 50px;
  gap: 2px;
}

div.data_wrapper.data_wrapper_m > ul.data_list {
  margin-top: 3rem;
  padding: 0 16px;
}

div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_1 {
  min-width: 18px;
  min-height: 18px;
}

div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_3 {
  height: 18px;
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_4 {
  height: 18px;
}

div.data_wrapper.data_wrapper_m > ul.data_list > li > p {
  min-width: 18px;
  min-height: 18px;
}

div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_5 {
  height: 18px;
}
div.chat_header_title_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
}
div.chat_header_title_row h1 {
  font-size: 24px;
}
div.chat_header_title_row button.add_cal {
  width: 12px;
  height: 12px;
  background: url(../images/icon_plus.svg) no-repeat;
  background-position: center;
}
div.chat_header_title_row button.add_cal:hover {
}
div.chat_header_title_row button.add_cal:active {
}
div.chat_header_title_row button.add_cal:focus {
}
div.chat_header_title_row_2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}
div.chat_header_title_row_2 h1 {
  color: #bbbbbb;
  font-size: 12px;
}
div.chat_header_title_row_2 button.next_sch {
  color: #bbbbbb;
  font-size: 12px;
  text-decoration: underline;
}
div.chat_header_title_row_2 button.next_sch:hover {
}
div.chat_header_title_row_2 button.next_sch:active {
}
div.chat_header_title_row_2 button.next_sch:focus {
}
div.cal_guide {
  width: 100%;
}
div.cal_guide ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-end;
  padding-right: 3rem;
}
div.cal_guide ul li {
  display: flex;
  align-items: center;
  gap: 4px;
}
div.cal_guide ul li p {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}
div.cal_guide ul li span {
  font-size: 10px;
  color: #bbbbbb;
}
div.cal_guide h1 {
  text-align: center;
  font-size: 12px;
  color: #bbbbbb;
  padding: 2rem 0;
  position: relative;
  width: 240px;
  margin: 0 auto;
  background: #fff;
}

div.cal_guide h1::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #dddddd;
  position: absolute;
  right: 100%;
  top: calc(50% - 0.5px);
}

div.cal_guide h1::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 100%;
  top: calc(50% - 0.5px);
}
div.cal_guide ul li:nth-of-type(1) p {
  background: #4587f5;
}
div.cal_guide ul li:nth-of-type(2) p {
  background: #00cc66;
}
div.cal_guide ul li:nth-of-type(3) p {
  background: #aabbcc;
}
div.cal_guide ul li:nth-of-type(4) p {
  background: #ffcc00;
}
div.cal_guide ul li:nth-of-type(5) p {
  background: #ff5555;
}
div.chat_wrapper.white_m_wrapper {
  background: #fff;
}
div.chat_wrapper.white_m_wrapper div.white_m_header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
}
div.chat_wrapper.white_m_wrapper div.white_m_header img {
  position: absolute;
  left: 16px;
  cursor: pointer;
}
div.chat_wrapper.white_m_wrapper div.white_m_header span {
  font-size: 14px;
}
div.chat_wrapper.white_m_wrapper div.white_m_header button {
  font-size: 14px;
  color: #4587f5;
  position: absolute;
  right: 16px;
}
div.chat_wrapper.white_m_wrapper div.white_m_header button:hover {
}
div.chat_wrapper.white_m_wrapper div.white_m_header button:active {
}
div.chat_wrapper.white_m_wrapper div.white_m_header button:focus {
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper {
  padding: 0 16px;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper h1 {
  color: #bbb;
  font-size: 12px;
  font-weight: 300;
  margin: 10px 0;
  display: flex;
  gap: 4px;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper h1 span.blue {
}
div.chat_wrapper.white_m_wrapper
  div.selected_member_wrapper
  ul.selected_member_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 17px;
  overflow: auto;
  padding: 1rem 0;
  border-bottom: 1px solid #bbbbbb;
}
div.chat_wrapper.white_m_wrapper
  div.selected_member_wrapper
  ul.selected_member_list
  li {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.chat_wrapper.white_m_wrapper
  div.selected_member_wrapper
  ul.selected_member_list
  div.selected_profile_img {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
div.chat_wrapper.white_m_wrapper
  div.selected_member_wrapper
  ul.selected_member_list
  div.selected_profile_img
  p.del_selected_memeber {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  min-width: 44px;
  background: url(../images/icon_profile_basic.svg) no-repeat;
  min-height: 44px;
  background-size: cover;
  background-position: center;
}
div.chat_wrapper.white_m_wrapper
  div.selected_member_wrapper
  ul.selected_member_list
  div.selected_profile_img
  span {
  font-size: 10px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list {
  padding: 0 16px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list > h1 {
  color: #bbb;
  font-size: 12px;
  font-weight: 300;
  margin: 10px 0;
  display: flex;
  gap: 4px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list h1 span {
  color: #4587f5;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list li.active {
  background: none !important;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px 0;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list p {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div h1 {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div span {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list p {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  min-width: 44px;
  background: url(../images/icon_profile_basic.svg) no-repeat;
  min-height: 44px;
  background-size: cover;
  background-position: center;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div {
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 0;
  width: 100%;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div h1 {
  font-weight: 500;
  font-size: 14px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div span {
  font-size: 12px;
  color: #bbbbbb;
}

.remove_sel_button {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: url(../images/icon_close_s_w.svg) no-repeat;
  background-color: #aaa;
  background-position: center;
  right: 0;
  top: 0;
  cursor: pointer;
}
div.create_wrapper_2 {
}
div.create_wrapper_2 div.photo_area {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}
div.create_wrapper_2 div.photo_area label {
  border-radius: 100%;
  width: 68px;
  height: 68px;
  background: url(../images/icon_profile_basic.svg) no-repeat;
  background-size: cover !important;
  background-position: center !important;
  position: relative;
  cursor: pointer;
}
div.create_wrapper_2 div.photo_area label input {
  display: none;
}
div.create_wrapper_2 div.photo_area label p.icon_photo {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url(../images/icon_photo.svg) no-repeat;
  background-position: center;
  background-size: cover;
}
div.create_wrapper_2 div.input_wrapper {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
}
div.create_wrapper_2 div.input_wrapper h1 {
}
div.create_wrapper_2 div.input_wrapper input {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
}
div.create_wrapper_2 ul {
}
div.create_wrapper_2 > ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 1rem 16px;
  border-bottom: 1px solid #f2f2f2;
}

div.create_wrapper_2 ul li:nth-of-type(2) {
  display: block;
}

div.create_wrapper_2 ul li div.create_col {
}
div.create_wrapper_2 ul li div.toggle_button {
}

.btn_toolTip {
  width: 16px;
  height: 16px;
  display: block;
  margin: 0 0 0 4px;
}
.btn_toolTip img {
  width: 100%;
  display: block;
}
.nameBtnCom {
  display: flex;
  align-items: center;
}

div.create_wrapper_2 ul li div.flex_inner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
div.create_wrapper_2 ul li input {
  height: 36px;
  background: #f2f2f2;
  border-radius: 8px;
  width: 100%;
  margin-top: 2rem;
  padding-left: 1rem;
  font-size: 24px;
}
div.create_wrapper_2 ul li h1 {
}
div.create_wrapper_2 ul li span {
}
div.create_wrapper_2 ul li p {
}
div.create_wrapper_2 ul li div.create_col {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
div.create_wrapper_2 ul li div.create_col h1 {
  font-size: 14px;
}
div.create_wrapper_2 ul li div.create_col span {
  font-size: 12px;
  color: #888888;
}
.toggle_button {
  min-width: 40px;
  width: 40px;
  min-height: 24px;
  left: 0px;
  top: 0px;
  background: #dbdbdb;
  border-radius: 1000px;
  position: relative;
  cursor: pointer;
}
.toggle_button p {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 3px;
  top: 2px;
  background: #ffffff;
  border-radius: 1000px;
}

.toggle_button.active {
  background: #4587f5;
}
.toggle_button.active p {
  left: 18px;
}

div.create_wrapper_2 button.create_submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 80px;
  top: 32px;
  background: #dddddd;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
  margin-top: 32px;
}
div.create_wrapper_2 button.create_submit_button.active {
  background: #4587f5;
}
div.create_wrapper_2 button.create_submit_button:hover {
}
div.create_wrapper_2 button.create_submit_button:active {
}
div.create_wrapper_2 button.create_submit_button:focus {
}

div.white_m_header {
}
div.white_m_header img {
}
div.teamwork_row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: calc(100% - 16px);
  padding: 1rem 16px;
  cursor: pointer;
}
div.teamwork_row p {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: url(../images/icon_profile_basic.svg);
  background-position: center !important;
  background-size: cover !important;
}
div.teamwork_row div.teamwork_col {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
div.teamwork_row div.teamwork_col h1 {
  font-size: 14px;
  font-weight: 500;
}
div.teamwork_row div.teamwork_col span {
  font-size: 12px;
  color: #888888;
}
ul.menu_list_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 53px;
  padding: 2rem 0;
  border-bottom: 1px solid #dddddd;
}
ul.menu_list_1 li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}

ul.menu_list_1 li p {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.menu_list_1 li img {
}

ul.menu_list_1 li:nth-of-type(2) img {
  width: 31px;
}
ul.menu_list_1 li:nth-of-type(3) img {
  width: 27px;
}

ul.menu_list_1 li span {
  font-size: 10px;
}
ul.menu_list_2 {
}
ul.menu_list_2 li {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 16px;
  padding-right: 4rem;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: 91%;
  cursor: pointer;
}
ul.menu_list_2 li h1 {
  font-size: 14px;
  font-weight: 500;
}
ul.menu_list_2 li span {
  color: #888888;
  font-size: 14px;
}
ul.menu_list_3 {
}
ul.menu_list_3 li {
  padding: 1.5rem 16px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
ul.menu_list_3 li div.menu_list_3_text {
}
ul.menu_list_3 li div.menu_list_3_text h1 {
}
ul.menu_list_3 li div.menu_list_3_text span {
  font-size: 12px;
  color: #888888;
}
ul.menu_list_3 li div.menu_list_3_text h1 {
  font-size: 14px;
  font-weight: 500;
}
ul.menu_list_3 li div.toggle_button {
}
ul.menu_list_3 li div.toggle_button p {
}
ul.menu_list_3 li div.toggle_button p {
}

.white_m_header_img_1 {
}
.white_m_header_img_2 {
  left: inherit !important;
  right: 16px;
}

.button_change_password {
  height: 36px;
  top: 84px;
  background: #f2f2f2;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
  margin-top: 12px;
  align-items: center;
  justify-content: center;
}

.teamword_info_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 52px;
  border-bottom: 1px solid #f2f2f2;
  padding-right: 4rem;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: 90%;
  cursor: pointer;
}
.teamword_info_row h1 {
  font-size: 14px;
  font-weight: 500;
}
.teamword_info_row span {
  font-size: 14px;
  color: #888888;
}

div.pers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 16px;
  border-bottom: 1px solid #f2f2f2;
}
div.pers h1 {
  font-size: 14px;
  font-weight: 500;
}
div.pers ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  height: 36px;
  border-radius: 8px;
  padding: 2px;
  width: 180px;
}
div.pers ul li.active {
  height: 100%;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111111 !important;
}
div.pers ul li {
  width: 50%;
  text-align: center;
  font-size: 12px;
  color: #bbbbbb;
  cursor: pointer;
}
div.all_memeber_list.all_memeber_list_2 {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li.active {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li.active p {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li.active div {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li.active h1 {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li.active span {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li {
  background: none !important;
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li p {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li div {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li h1 {
}
div.all_memeber_list.all_memeber_list_2 ul.member_list li span {
}

.member_list .master {
}
.member_list .sub_master {
}

.member_list .master::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: url(../images/icon_master_1.svg) no-repeat;
  background-position: center;
  right: 0;
  bottom: 0;
}

.member_list .sub_master::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: url(../images/icon_master_2.svg) no-repeat;
  background-position: center;
  right: 0;
  bottom: 0;
}
div.banner {
  width: 100%;
  height: 68px;
  background: url(../images/banner.svg);
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
div.m_photo_list {
  padding: 0 16px;
  overflow: auto;
  height: 100%;
}
div.m_photo_list div.m_photo_list_row {
  margin-top: 1.5rem;
}
div.m_photo_list div.m_photo_list_row h1 {
  font-size: 12px;
  color: #bbbbbb;
  padding: 1rem 0;
}

div.m_photo_list div.m_photo_list_row:nth-of-type(1) {
  margin-top: 0;
}

div.m_photo_list div.m_photo_list_row ul {
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  overflow: hidden;
  gap: 1px;
}
div.m_photo_list div.m_photo_list_row ul li {
  width: calc(33.333% - 1px);
  height: 90px;
  background: url(../images/sample_img_1.png) no-repeat;
  background-position: center !important;
  background-size: cover;
  cursor: pointer;
}
ul.notice_list_wrapper {
  margin-top: 2rem;
}
ul.notice_list_wrapper li {
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 0 16px;
  background: url(../images/icon_right_s.svg) no-repeat;
  background-position: calc(100% - 16px);
  cursor: pointer;
  border-bottom: 1px solid #f2f2f2;
}
ul.notice_list_wrapper li h1 {
  font-size: 14px;
}
ul.notice_list_wrapper li span {
  font-size: 12px;
  color: #888888;
}

.header_right {
  left: inherit !important;
  right: 16px;
}
div.notice_detail_wrapper.notice_detail_wrapper_2 {
  padding: 0 16px;
  padding-top: 1.5rem;
}
div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area {
}
div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area h1 {
  font-size: 20px;
}
div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area span {
}
div.notice_detail_wrapper.notice_detail_wrapper_2 div.notice_contents {
}
div.notice_detail_wrapper.notice_detail_wrapper_2 div.notice_contents img {
}
div.notice_detail_wrapper.notice_detail_wrapper_2 div.notice_contents span {
  font-size: 14px;
}
div.submit_date_wrapper {
  padding: 0 16px;
}
div.submit_date_wrapper div.submit_date_row {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 1rem;
}
div.submit_date_wrapper div.submit_date_row div.input_wrapper {
  margin: 0;
  width: 100%;
  position: relative;
}
div.submit_date_wrapper div.submit_date_row div.input_wrapper h1 {
}
div.submit_date_wrapper div.submit_date_row div.input_wrapper input {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
}
div.submit_date_wrapper div.submit_date_row div.textArea_row {
}
div.submit_date_wrapper div.submit_date_row div.textArea_row h1 {
  color: #bbbbbb;
  font-size: 12px;
}
div.submit_date_wrapper div.submit_date_row div.textArea_row textarea {
  position: static;
  width: 100%;
  height: 88px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  resize: none;
  padding: 1rem;
}
div.submit_date_wrapper div.submit_date_row div.textArea_row span.red {
}
div.submit_date_wrapper div.submit_date_row div.date_picker_row {
}
div.submit_date_wrapper div.submit_date_row div.date_picker_row h1 {
  color: #bbbbbb;
  font-size: 12px;
}
div.submit_date_wrapper
  div.submit_date_row
  div.date_picker_row
  div.date_picker_container {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  background: url(../images/icon_cal_g.svg) no-repeat;
  background-position: 96%;
  background-color: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-size: 14px;
  color: #bbbbbb;
}
div.submit_date_wrapper div.submit_date_row div.date_picker_row span.red {
}
div.submit_date_wrapper div.input_wrapper {
  width: 100%;
}
div.submit_date_wrapper div.input_wrapper h1 {
}
div.submit_date_wrapper div.input_wrapper h1 span {
}
div.submit_date_wrapper div.input_wrapper div.input_outter {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
div.submit_date_wrapper div.input_wrapper div.input_outter input {
  padding-left: 1rem;
}
div.submit_date_wrapper div.input_wrapper div.input_outter span {
}
div.submit_date_wrapper div.input_wrapper div.file_add_section {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  ul.b_submit_file_list {
}
div.submit_date_wrapper div.input_wrapper div.file_add_section li.added_file {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.added_file
  p.file_del_button {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.added_file
  div.file_icon {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.added_file
  span.file_name {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.added_file
  span.file_size {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.file_add_button {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.file_add_button
  label {
}
div.submit_date_wrapper
  div.input_wrapper
  div.file_add_section
  li.file_add_button
  input {
}
div.submit_date_wrapper div.input_wrapper button.date_submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 72px;
  top: 32px;
  background: #4587f5;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
  margin-top: 32px;
}
div.submit_date_wrapper div.input_wrapper button.date_submit_button:hover {
}
div.submit_date_wrapper div.input_wrapper button.date_submit_button:active {
}
div.submit_date_wrapper div.input_wrapper button.date_submit_button:focus {
}

.file_add_subText {
  font-size: 12px;
  color: #bbbbbb;
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-between;
}
.white_m_header .right img {
  position: static !important;
}

.white_m_header .right {
  display: flex;
  position: absolute;
  right: 16px;
  flex-direction: row;
  gap: 12px;
}
.white_m_header .right li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
}
div.date_detail_3 {
  width: 100%;
  padding: 0 16px;
}
div.date_detail_3 h1 {
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}
div.date_detail_3 h1 p {
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
div.date_detail_3 div.player_row {
  margin-top: 2rem;
  overflow: auto;
}
div.date_detail_3 div.player_row > h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.date_detail_3 div.player_row div.player_row_inner {
  display: flex;
  align-items: center;
  gap: 7px;
}
div.date_detail_3 div.player_row div.player_row_inner h1 {
  font-size: 14px;
}
div.date_detail_3 div.player_row div.player_row_inner ul {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
div.date_detail_3 div.player_row div.player_row_inner li {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: url(../images/icon_profile_basic.svg) no-repeat;
  background-size: cover !important;
  background-position: center !important;
  margin-left: -11px;
  border: 1px solid #eaeaea;
  background-color: #fff !important;
  cursor: pointer;
}
div.date_detail_3 div.player_row div.payment_info_row_3 {
  margin-top: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dddddd;
  display: none;
}
div.date_detail_3 div.player_row div.payment_info_row_3 h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.date_detail_3 div.player_row div.payment_info_row_3 span {
  font-size: 14px;
}
div.date_detail_3 div.player_row div.date_detail_textArea {
  margin-bottom: 2rem;
}
div.date_detail_3 div.player_row div.date_detail_textArea h1 {
  font-size: 14px;
  padding-top: 1.5rem;
}
.editType {
  font-size: 0.875em;
  color: #999;
  font-family: "noto_sans", "sans-serif";
  display: none;
}
div.date_detail_3 div.player_row div.date_detail_textArea span {
  font-size: 14px;
  color: #888888;
  line-height: 24px;
  display: block;
  width: 100%;
  white-space: pre-wrap;
}
div.date_detail_3 div.player_row ul.player_row_2 {
  padding-bottom: 2rem;
  border-bottom: 1px solid #dddddd;
}
div.date_detail_3 div.player_row ul.player_row_2 li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
div.date_detail_3 div.player_row ul.player_row_2 h1 {
  font-size: 14px;
}
div.date_detail_3 div.player_row ul.player_row_2 span {
  font-size: 14px;
  color: #888888;
}
div.date_detail_3 div.player_row ul.player_row_3 {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dddddd;
}
div.date_detail_3 div.player_row ul.player_row_3 li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
div.date_detail_3 div.player_row ul.player_row_3 h1 {
  font-size: 14px;
}
div.date_detail_3 div.player_row ul.player_row_3 span.blue {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.addrCopyTextArea {
  position: fixed;
  top: -9999px;
  left: -9999px;
}
div.date_detail_3 div.player_row div.respect_price {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
div.date_detail_3 div.player_row div.respect_price div.respect_price_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
div.date_detail_3 div.player_row div.respect_price h1 {
  font-size: 14px;
}
div.date_detail_3 div.player_row div.respect_price span {
  color: #888888;
  font-size: 14px;
}
div.date_detail_3 div.player_row span.file_add_subText {
}
div.date_detail_3 div.player_row div.file_add_section {
}
div.date_detail_3 div.player_row div.file_add_section ul.b_submit_file_list {
}
div.date_detail_3 div.player_row div.file_add_section li.added_file {
}
div.date_detail_3
  div.player_row
  div.file_add_section
  li.added_file
  p.file_del_button {
}
div.date_detail_3
  div.player_row
  div.file_add_section
  li.added_file
  div.file_icon {
}
div.date_detail_3
  div.player_row
  div.file_add_section
  li.added_file
  span.file_name {
}
div.date_detail_3
  div.player_row
  div.file_add_section
  li.added_file
  span.file_size {
}
div.date_detail_3 div.player_row div.file_add_section li.file_add_button {
}
div.date_detail_3 div.player_row div.file_add_section li.file_add_button label {
}
div.date_detail_3 div.player_row div.file_add_section li.file_add_button input {
}

div.submit_date_wrapper.submit_card_wrapper {
}
div.submit_date_wrapper.submit_card_wrapper div.submit_date_row {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.textArea_row {
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.textArea_row
  div.input_wrapper {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.submit_card_wrapper .input_outter input {
  background: none !important;
  flex: auto !important;
  order: unset !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.textArea_row
  h1 {
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.textArea_row
  select {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;
  background-position: center right 10px !important;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.textArea_row
  input {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.textArea_row
  span {
  font-size: 14px;
}
div.submit_date_wrapper.submit_card_wrapper
  div.submit_date_row
  div.date_picker_row
  input {
  position: static;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 25px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 0px;
  padding-left: 1rem;
}

div.submit_date_wrapper.submit_card_wrapper button.date_submit_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 160px;
  height: 44px;
  left: 80px;
  top: 32px;
  background: #4587f5;
  border-radius: 1000px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
}
.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  display: none;
}
.no_data span {
  font-size: 12px;
  color: #aaa;
}
.no_data.no_memberData {
  text-align: center;
}
.no_data.no_memberData span {
  display: block;
}
.no_data_addMember {
  display: inline-block;
  color: #4587f5;
  font-size: 14px;
  margin: 12px 0 0;
}
.default_area {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}
.default_area img {
  opacity: 0.3;
}
div.popup_wrapper_2 {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
div.popup_wrapper_2 .fc .fc-daygrid-body,
div.popup_wrapper_2 table {
  width: 100% !important;
}
div.popup_wrapper_2 .fc-toolbar-title {
  display: block !important;
}
div.popup_wrapper_2 div.date_picker_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: relative;
  width: 380px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  z-index: 99;
  padding: 1rem;
  max-width: 95%;
}
div.popup_wrapper_2 div.date_picker_box h1 {
  font-size: 16px;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}
div.popup_wrapper_2 div.date_picker_box div.toggle_row {
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  height: 50px;
}
div.popup_wrapper_2 div.date_picker_box div.toggle_row span {
  font-size: 14px;
}
div.popup_wrapper_2
  div.date_picker_box
  div.toggle_row
  div.toggle_button.active {
}
div.popup_wrapper_2 div.date_picker_box div.toggle_row p {
}
div.popup_wrapper_2 div.date_picker_box div.time_col {
  width: 100%;
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.time_row.start {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-direction: row;
}

.change_pass_wrapper div.input_wrapper {
  width: 100% !important;
}
.id_check_button {
  position: absolute;
  right: 1rem;
  color: #aaa;
  font-size: 13px;
  cursor: pointer;
}
.input_row .red {
  font-size: 12px;
  margin-top: 3px;
}

div.popup_wrapper_2 div.date_picker_box div.time_col div.time_row.end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-direction: row;
  margin-top: 5px;
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col span {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col img {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 img {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col {
  width: 50%;
  height: 44px;
  background: #f2f2f2;
  border-radius: 8px;

  /* Inside Auto Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  cursor: pointer;
  border: 1px solid #ffffff;
}

div.popup_wrapper_2 div.date_picker_box div.time_col div.col.active {
  border: 1px solid #3788d8;
}

div.popup_wrapper_2 div.date_picker_box div.time_col div.col span {
  font-size: 12px;
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col img {
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 {
  width: 50%;
  height: 44px;
  background: #f2f2f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

div.popup_wrapper_2 div.date_picker_box div.time_col div.col2.active {
  border: 1px solid #3788d8;
}

div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input {
  width: auto;
  display: flex;
  min-width: 0;
  font-size: 12px;
  cursor: pointer;
  height: 100%;
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 img {
}
div.popup_wrapper_2 div.date_picker_box div.fullcal_wrapper {
}
div.popup_wrapper_2 div.date_picker_box ul.button_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  gap: 19px;
  padding-bottom: 1rem;
}
div.popup_wrapper_2 div.date_picker_box ul.button_wrapper li {
  font-size: 14px;
  cursor: pointer;
}
div.popup_wrapper_2 div.date_picker_box ul.button_wrapper li.blue {
}
div.popup_wrapper_2 div.background_black {
}
.fc .fc-scroller {
  overflow: auto !important;
}
div.chat_sub_write_menu {
  height: 88px;
  background: #f7f7f7;
  padding: 0 40px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 380px);
  display: none;
}
div.chat_sub_write_menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  height: 100%;
}
div.chat_sub_write_menu ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
div.chat_sub_write_menu ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
div.chat_sub_write_menu ul li a:hover {
}
div.chat_sub_write_menu ul li a:active {
}
div.chat_sub_write_menu ul li a:focus {
}
div.chat_sub_write_menu ul li label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
div.chat_sub_write_menu ul li p {
}
div.chat_sub_write_menu ul li span {
}
div.chat_sub_write_menu ul li input {
  display: none;
}
div.chat_sub_write_menu ul li p {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-position: center !important;
  background-size: cover !important;
}
div.chat_sub_write_menu ul li span {
  font-size: 10px;
}
div.chat_sub_write_menu ul li:nth-of-type(1) p {
  background: url(../images/icon_chat_file.svg) no-repeat;
}
div.chat_sub_write_menu ul li:nth-of-type(2) p {
  background: url(../images/icon_chat_notice.svg) no-repeat;
}

div.chat_file_row {
}
div.chat_file_row div.chat_file_top {
  display: flex;
  align-items: center;
  gap: 6px;
}
div.chat_file_row div.chat_file_top p.file_icon {
  border: 1px solid #dddddd;
  min-height: 36px;
  border-radius: 100%;
  min-width: 36px;
  width: 36px;
  height: 36px;
  background: url(../images/icon_file.svg) no-repeat;
  background-position: center;
}
div.chat_file_row div.chat_file_top span {
  font-size: 13px;
}
div.chat_file_row div.chat_file_bottom {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
div.chat_file_row div.chat_file_bottom h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.chat_file_row div.chat_file_bottom span {
  font-size: 12px;
  color: #bbbbbb;
}

div.chat_file_row.chat_file_row_right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}
div.chat_file_row.chat_file_row_right div.chat_info_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}
div.chat_file_row.chat_file_row_right div.chat_info_right span.un_read {
  font-size: 12px;
  color: #4587f5;
}
div.chat_file_row.chat_file_row_right div.chat_info_right p.time {
  color: #bbbbbb;
  font-size: 12px;
}
div.chat_file_row.chat_file_row_right div.chat_file_row_inner {
  border: 1px solid #dddddd;
  border-radius: 16px;
  max-width: 260px;
  width: 80%;
  padding: 6px 12px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
div.chat_file_row.chat_file_row_right
  div.chat_file_row_inner
  div.chat_file_top {
}
div.chat_file_row.chat_file_row_right
  div.chat_file_row_inner
  div.chat_file_top
  p.file_icon {
}
div.chat_file_row.chat_file_row_right
  div.chat_file_row_inner
  div.chat_file_top
  span {
  overflow: hidden;
  height: 39px;
  text-overflow: ellipsis;
}
div.chat_file_row.chat_file_row_right
  div.chat_file_row_inner
  div.chat_file_bottom {
}
div.chat_file_row.chat_file_row_right
  div.chat_file_row_inner
  div.chat_file_bottom
  h1 {
}
div.chat_file_row.chat_file_row_right
  div.chat_file_row_inner
  div.chat_file_bottom
  span {
}
.chat_img_right {
  max-width: 480px;
  width: 100%;
  /* max-height: 320px;
    height: 100%; */
  border-radius: 23px;
  cursor: pointer;
}

.chat_row_img_2 .chat_row_img_2_inner {
  max-width: 480px;
  width: 100%;
  /* max-height: 320px;
    height: 100%; */
  border-radius: 23px;
  cursor: pointer;
}

div.img_viewer {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #333333 !important;
  padding: 22px;
  display: none;
}
div.img_viewer div.img_viewer_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
div.img_viewer div.img_viewer_header img.close_viewer {
  width: 15px;
  cursor: pointer;
  filter: invert(1);
  padding: 0 4px;
  box-sizing: content-box;
}
div.img_viewer div.img_viewer_header span {
  font-size: 14px;
  color: #fff;
}
div.img_viewer div.img_viewer_header img.download_img {
  width: 22px;
  cursor: pointer;
  filter: invert(1);
}
div.img_viewer div.slide_left {
  width: 12px;
  height: 36px;
  background: url(../images/icon_slide_left.svg) no-repeat;
  position: absolute;
  left: 22px;
  top: calc(50% - 32px);
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
div.img_viewer div.slide_right {
  width: 12px;
  height: 36px;
  background: url(../images/icon_slide_right.svg) no-repeat;
  position: absolute;
  right: 22px;
  top: calc(50% - 32px);
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
div.img_viewer div.img_viewer_bottom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  bottom: 22px;
  left: 0;
  padding: 0 22px;
}
div.img_viewer div.img_viewer_bottom div.size_controller {
  display: flex;
  align-items: center;
  gap: 15px;
}
div.img_viewer div.img_viewer_bottom div.size_controller img {
  cursor: pointer;
}
div.img_viewer div.img_viewer_bottom div.size_controller span {
  font-size: 14px;
  color: #fff;
}
div.img_viewer div.img_viewer_bottom ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  cursor: pointer;
}
div.img_viewer div.img_viewer_bottom ul li {
}
div.img_viewer div.img_viewer_bottom ul img {
}
.location_list {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  z-index: 9;
  padding: 1rem;
  display: none;
  max-height: 400px;
  overflow-y: auto;
}

.location_list li {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
  min-height: 40px;
  cursor: pointer;
}
.location_list li:last-of-type {
  border-bottom: 0;
}
div.schedule_wrapper {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 12px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 6px 24px rgb(0 0 0 / 10%);
  border-radius: 23px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 1.3rem;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: calc(100% - 20px);
  position: relative;
  overflow: visible;
}

div.schedule_header.request {
  background: #4587f5 !important;
}
div.schedule_header.complete {
  background: #aabbcc !important;
}
div.schedule_header.admit {
  background: #00cc66 !important;
}
div.schedule_header.cancel {
  background: #ff5555 !important;
}
div.schedule_header.stay {
  background: #ffcc00 !important;
}

div.schedule_wrapper div.schedule_header {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 25px;
  background: #333333;
  border-radius: 23px 23px 0 0;
}
div.schedule_wrapper div.schedule_header h1 {
  font-size: 14px;
  color: #fff;
}
div.schedule_wrapper div.schedule_header p {
  font-size: 14px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
div.schedule_wrapper div.schedule_row_1 {
  margin: 0px 25px;
  border-bottom: 1px solid #dddddd;
  padding: 20px 0;
  width: 100%;
  width: calc(100% - 40px);
}
div.schedule_wrapper div.schedule_row_1 h1 {
  font-size: 14px;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.schedule_wrapper div.schedule_row_1 h2 {
  font-size: 14px;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.schedule_wrapper div.schedule_row_2 {
  width: 100%;
  padding: 0 25px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
div.schedule_wrapper div.schedule_row_2 div.schedule_row_2_inner_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: flex-start;
}
div.schedule_wrapper div.schedule_row_2 div.schedule_row_2_inner_row img {
}
div.schedule_wrapper div.schedule_row_2 div.schedule_row_2_inner_row span {
  font-size: 12px;
  color: #888888;
}
div.schedule_wrapper div.schedule_row_2 div.schedule_row_2_inner_row br {
}
div.schedule_wrapper span.schedule_price.blue {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  padding-top: 30px;
}
.chat_file_downloader {
  display: none;
}
div.chat_row_2.chat_row_2_file {
}
div.chat_row_2.chat_row_2_file div.chat_profile {
}
div.chat_row_2.chat_row_2_file div.chat_info {
}
div.chat_row_2.chat_row_2_file div.chat_info div.chat_info_left {
}
div.chat_row_2.chat_row_2_file div.chat_info div.chat_info_left h1.chat_name {
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_wrapper {
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row {
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_row_inner {
  border: 1px solid #dddddd;
  border-radius: 16px;
  max-width: 260px;
  width: 80%;
  padding: 6px 12px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_info_right {
  justify-content: flex-end;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_top {
  display: flex;
  align-items: center;
  gap: 6px;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_top
  p.file_icon {
  border: 1px solid #dddddd;
  min-height: 36px;
  border-radius: 100%;
  min-width: 36px;
  width: 36px;
  height: 36px;
  background: url(../images/icon_file.svg) no-repeat;
  background-position: center;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_top
  span {
  overflow: hidden;
  height: 39px;
  text-overflow: ellipsis;
  font-size: 13px;
  word-break: break-all;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_bottom {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_bottom
  h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.chat_row_2.chat_row_2_file
  div.chat_info
  div.chat_info_left
  div.text_chat_inner_row
  div.chat_file_bottom
  span {
  font-size: 12px;
  color: #bbbbbb;
}

div.write_wrapper {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 1rem;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}
div.write_wrapper div.row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
div.write_wrapper div.row h1 {
}
div.write_wrapper div.row textarea {
  max-width: 100%;
  width: 100%;
  height: 44px;
  background: #f2f2f2;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  min-height: 88px;
  padding: 1rem;
  resize: none;
}
div.write_wrapper div.row textarea::placeholder,
div.write_wrapper div.row input::placeholder {
  color: #bbbbbb;
}

div.write_wrapper div.row h1 {
  font-size: 12px;
  color: #bbbbbb;
}
div.write_wrapper div.row input {
  max-width: 100%;
  width: 100%;
  height: 44px;
  background: #f2f2f2;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  padding-left: 1rem;
}
div.write_wrapper button.submit_notice {
  display: flex;

  flex-direction: row;

  justify-content: center;

  align-items: center;

  padding: 0px;

  width: 160px;

  height: 44px;

  background: #4587f5;

  border-radius: 1000px;

  /* Inside Auto Layout */

  margin: 8px auto;

  color: #fff;

  font-size: 14px;

  margin-top: 20px;
}
div.write_wrapper button.submit_notice:hover {
}
div.write_wrapper button.submit_notice:active {
}
div.write_wrapper button.submit_notice:focus {
}
.chat_loading {
  position: fixed;
  width: calc(100% - 380px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  top: 0;
  background: #fff;
  flex-direction: column;
  gap: 15px;
  bottom: 0;
  left: 380px;
}
.chat_loading span {
  font-size: 11px;
  color: #aaa;
  font-weight: 300;
  text-align: center;
}
.schedule_loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  background: #fff;
  flex-direction: column;
}
.schedule_loading span {
  font-size: 11px;
  color: #aaa;
  font-weight: 300;
  text-align: center;
}
.chat_loadingPay {
  position: fixed;
  width: calc(100% - 380px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  top: 0;
  background: #fff;
  flex-direction: column;
  gap: 15px;
  bottom: 0;
  left: 380px;
}
.chat_loadingPay span {
  font-size: 11px;
  color: #aaa;
  font-weight: 300;
}

.calender_loading {
  position: absolute;
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  top: 52px;
  background: #fff;
  flex-direction: column;
  gap: 15px;
  bottom: 0;
  left: 0;
}
.calender_loading span {
  font-size: 11px;
  color: #aaa;
  font-weight: 300;
}

.chat_loadingDate {
  position: fixed;
  width: calc(100% - 380px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  top: 0;
  background: #fff;
  flex-direction: column;
  gap: 15px;
  bottom: 0;
  left: 380px;
}
.chat_loadingDate span {
  font-size: 11px;
  color: #aaa;
  font-weight: 300;
}

.load_item {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 9984px 0 0 0 #4587f5, 9999px 0 0 0 #4587f5, 10014px 0 0 0 #4587f5;
  animation: dottyping 1s infinite linear;
}

@keyframes dottyping {
  0% {
    box-shadow: 9984px 0 0 0 #4587f5, 9999px 0 0 0 #4587f5,
      10014px 0 0 0 #4587f5;
  }
  16.667% {
    box-shadow: 9984px -5px 0 0 #4587f5, 9999px 0 0 0 #4587f5,
      10014px 0 0 0 #4587f5;
  }
  33.333% {
    box-shadow: 9984px -10px 0 0 #4587f5, 9999px -5px 0 0 #4587f5,
      10014px 0 0 0 #4587f5;
  }
  50% {
    box-shadow: 9984px -5px 0 0 #4587f5, 9999px -10px 0 0 #4587f5,
      10014px -5px 0 0 #4587f5;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #4587f5, 9999px -5px 0 0 #4587f5,
      10014px -10px 0 0 #4587f5;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #4587f5, 9999px 0 0 0 #4587f5,
      10014px -5px 0 0 #4587f5;
  }
  100% {
    box-shadow: 9984px 0 0 0 #4587f5, 9999px 0 0 0 #4587f5,
      10014px 0 0 0 #4587f5;
  }
}

.date_detail_button_wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  margin-top: 2rem;
}
.date_detail_button_wrapper li {
  background: #f2f2f2;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #111111;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
}
.end_teamwork {
  font-size: 14px;
  color: #ff5555;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}
.common_more_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  position: relative;
  width: 140px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
  border-radius: 16px;
  position: absolute;
  right: 0;
  top: 3rem;
  display: none;
}
.common_more_wrapper li {
  font-size: 12px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  padding: 13px;
  border-radius: 4px;
  padding-left: 5px;
}
.common_more_wrapper li:hover {
  background: #f1f1f1;
}
div.share_popup_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
}
div.share_popup_wrapper div.share_popup_box {
  position: relative;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: relative;
  width: 300px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
  border-radius: 16px;
  padding: 20px;
  padding-bottom: 4rem;
  padding-top: 1.5rem;
}
div.share_popup_wrapper div.share_popup_box h1 {
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  margin-bottom: 1.5rem;
}
div.share_popup_wrapper div.share_popup_box ul.share_team_work_list {
  width: 100%;
  max-height: 280px;
  height: 100%;
  overflow: auto;
}
div.share_popup_wrapper div.share_popup_box ul.share_team_work_list li.active {
}
div.share_popup_wrapper div.share_popup_box ul.share_team_work_list li {
  width: 100%;
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  li.active
  div.share_team_work_list_col {
  background: url(../images/icon_checked_active.svg) no-repeat !important;
  background-position: 95% !important;
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  div.share_team_work_list_col {
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  div.share_team_work_list_col
  p.share_team_work_list {
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  div.share_team_work_list_col
  span {
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  div.share_team_work_list_col {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  border-bottom: 1px solid #f2f2f2;
  background: url(../images/icon_check.svg) no-repeat !important;
  background-position: 95% !important;
  cursor: pointer;
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  li:last-of-type
  div.share_team_work_list_col {
  border-bottom: 0;
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  div.share_team_work_list_col
  p.share_team_work_list {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-position: center !important;
  background-size: cover !important;
  background: #aaa;
}
div.share_popup_wrapper
  div.share_popup_box
  ul.share_team_work_list
  div.share_team_work_list_col
  span {
  font-size: 14px;
  max-width: 70%;
  overflow: hidden;
}
div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button:nth-of-type(1) {
  position: absolute;
  right: 106px;
  bottom: 29px;
  color: #a1a1a1;
  cursor: pointer;
  bottom: 12px;
  height: 52px;
  width: 92px;
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button:nth-of-type(2) {
  color: #4587f5;
  font-weight: 500;
  position: absolute;
  bottom: 12px;
  height: 52px;
  width: 92px;
  right: 16px;
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button:hover {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button:active {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button:focus {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button.blue {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button.blue:hover {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button.blue:active {
}
div.share_popup_wrapper
  div.share_popup_box
  div.share_popup_box_button_wrapper
  button.blue:focus {
}
div.share_popup_wrapper div.share_popup_wrapper_background_black {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000050;
  z-index: 4;
}
div.lp_menu_wrapper.lp_menu_wrapper_my {
}
div.lp_menu_wrapper.lp_menu_wrapper_my div.lp_menu_wrapper_background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff50;
  z-index: 4;
  left: 0;
  top: 0;
}
div.lp_menu_wrapper.lp_menu_wrapper_my ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  position: relative;
  width: 155px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
  border-radius: 16px;
  position: absolute;
  right: 0px;
  top: 0rem;
  z-index: 99;
}
div.lp_menu_wrapper.lp_menu_wrapper_my ul li {
  font-size: 12px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  padding: 13px;
  border-radius: 4px;
  padding-left: 5px;
}

div.lp_menu_wrapper.lp_menu_wrapper_my ul li.redText {
  color: #ff5555;
}

div.chat_data_inner div.chat_row .chat_row_wrapper_my > p span {
  width: 100%;
  text-align: left;
  /* padding: 0 10px; */
  /* padding-bottom: 3px; */
  cursor: pointer;

  display: block;
  padding: 0;
  margin: 6px 0 0;
}

div.response_msg_wrapper {
  align-items: flex-start;
  background-color: #4587f5;
  border-radius: 23px 23px 23px 23px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;
  max-width: 480px;
  overflow: hidden;
  padding: 10px 15px;
  width: -webkit-max-content;
  width: max-content;
  word-break: break-all;
}
div.response_msg_wrapper div.response_msg_wrapper_top {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff30;
}
div.response_msg_wrapper div.response_msg_wrapper_top h1 {
  font-size: 14px;
  color: #fff;
  text-align: left;
  margin-bottom: 1px;
}
div.response_msg_wrapper div.response_msg_wrapper_top span {
  font-size: 12px;
  color: #ffffff60;
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.response_msg_wrapper p {
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
}
.confirm_wrapper .right {
  right: 16px !important;
}
.chat_data_inner p a {
  color: inherit;
  text-decoration: underline;
  display: inline;
  word-break: break-all;
}
@media (max-width: 1023px) {
  .chat span {
    right: 0px;
  }
  .chat_img_right,
  .chat_row_img_2 .chat_row_img_2_inner {
    max-width: 65%;
  }
  div.chat_sub_write_menu {
    width: calc(100% - 320px);
  }
}
@media (max-width: 767px) {
  .cal_select {
    -webkit-appearance: none;
    background: url("../images/icon_dropdown.svg") no-repeat;
    background-position: right;
    display: flex;
    padding-right: 1.6rem;
  }
  .fc .fc-daygrid-day-bottom {
    /* height: 20px; */
  }
  .confirm_wrapper .right {
    /* right: 0px !important; */
  }
  .chat_loading {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    top: 0;
    background: #fff;
    flex-direction: column;
    gap: 15px;
    bottom: 0;
    left: 0;
  }
  .chat_loadingPay {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    top: 0;
    background: #fff;
    flex-direction: column;
    gap: 15px;
    bottom: 0;
    left: 0;
  }
  .chat_loadingDate {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    top: 0;
    background: #fff;
    flex-direction: column;
    gap: 15px;
    bottom: 0;
    left: 0;
  }
  div.response_msg_wrapper {
    max-width: 220px;
    word-break: keep-all;
    width: 100%;
    word-break: break-all;
  }
  .chat_img_right {
    max-width: calc(100% - 70px);
    border-radius: 16px;
    width: auto;
  }
  div.img_viewer {
    z-index: 9999;
  }
  div.schedule_wrapper div.schedule_row_2 {
    padding: 0 15px;
    margin-top: 15px;
  }
  div.schedule_wrapper span.schedule_price.blue {
    padding-top: 10px;
    font-size: 16px;
  }
  div.chat_sub_write_menu {
    width: 100%;
  }
  ul.menu_list_1 {
    gap: 73px;
  }
  ul.menu_list_2 li {
    background-position: calc(100% - 16px);
    padding-right: 40px;
  }

  div.popup_wrapper_2 div.date_picker_box {
    overflow: auto;
    max-height: 620px;
  }
  div.schedule_wrapper div.schedule_row_1 {
    padding: 14px 0;
  }
  div.schedule_wrapper div.schedule_header p {
    font-size: 12px;
  }
  div.schedule_wrapper div.schedule_header h1 {
    font-size: 14px;
  }
  div.schedule_wrapper div.schedule_row_1 h1 {
    font-size: 12px;
    height: 36px;
  }
  div.schedule_wrapper div.schedule_row_1 h2 {
    font-size: 12px;
    height: 36px;
  }
  ul.data_list.iosPadding {
    padding-bottom: 145px;
  }
}
#fc-dom-1 {
  display: none;
}
div.popup_wrapper_2 div.date_picker_box div.fullcal_wrapper {
  width: 100%;
  padding-top: 3rem;
}
.fc .fc-daygrid-day-number {
  font-size: 12px;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0;
}
.fc .fc-scroller-harness {
  overflow: auto;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-right: 0;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: none;
}
.fc .fc-daygrid-day-frame {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.popup_wrapper_2 div.date_picker_box div.fullcal_wrapper {
  padding-top: 1rem;
}
/* 롤데이트피커 커스텀 */
.rolldate-container .rolldate-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 273px;
  z-index: 1000;
  background: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate3d(0, 273px, 0);
  transform: translate3d(0, 273px, 0);
  max-width: 400px !important;
  left: calc(50% - 200px) !important;
  border-radius: 15px 15px 0 0;
  bottom: 0;
  transform: none;
}
div.popup_wrapper_2 div.date_picker_box div.time_col div.col {
  width: 100%;
}

.chat_row_2 .response_msg_wrapper {
  background-color: #f2f2f2;
}

.chat_row_2 div.response_msg_wrapper div.response_msg_wrapper_top h1,
.chat_row_2 div.response_msg_wrapper p,
.chat_row_2 div.response_msg_wrapper div.response_msg_wrapper_top span {
  color: #111 !important;
}
.chat_row_2 div.response_msg_wrapper div.response_msg_wrapper_top {
  border-bottom: 1px solid #00000030;
}

.chat_add_friend {
  width: calc(100% - 32px);
  border-radius: 8px;
  background: #f2f2f2;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 24px;
  display: none;
}

.fc-more-popover {
  display: none !important;
}

@media (max-width: 768px) {
  .chat_search_popup_wrapper_box .fc,
    /* .chat_search_popup_wrapper_box .fc *, */
    .chat_search_popup_wrapper_box .fc *:before,
    .chat_search_popup_wrapper_box .fc *:after {
    max-height: 50px;
  }

  .rolldate-container .rolldate-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 273px;
    z-index: 1000;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate3d(0, 273px, 0);
    transform: translate3d(0, 273px, 0);
    max-width: 400px !important;
    left: 0 !important;
    border-radius: 15px 15px 0 0;
    bottom: 0;
    transform: none;
    width: 100%;
  }
}
