/* 2022-02-24 인수인계
사용하지 않는 파일입니다. */

li.skeleton_list {
    background: #ededed;
    border-radius: 13px;
    padding: 1rem;
    gap: 10px !important;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
li.skeleton_list div.skeleton_sq {
    min-width: 50px;
    min-height: 50px;
    background-color: #d9d9d9;
    border-radius: 100%;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
li.skeleton_list div.skeleton_bar_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
}
li.skeleton_list div.skeleton_bar_wrapper p {
    width: 100%;
    height: 10px;
    background: #d9d9d9;
    border-radius: 10px;
    display: flex;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}

h1.skeleton_atom {
    font-size: 0 !important;
    background-color: #d9d9d9;
    border-radius: 7px;
    height: 40px;
    width: 100px;
    display: flex;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}

span.skeleton_atom {
    font-size: 0 !important;
    background-color: #d9d9d9;
    border-radius: 7px;
    height: 40px;
    width: 100px;
    display: flex;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}

li.skeleton_atom {
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
    min-width: 30px !important;
    width: 30px !important;
    min-height: 30px !important;
    height: 30px !important;
    border-radius: 5px !important;
    background: #d9d9d9;
}

.left_menu_bar li.skeleton_atom:nth-of-type(1) {
    width: 44px !important;
    height: 44px !important;
    border-radius: 100% !important;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    background-size: cover !important;
    background-position: center !important;
    cursor: pointer;
    background-color: #d9d9d9;
    background-blend-mode: hue;
}

li.skeleton_atom * {
    opacity: 0;
}

div.sk_member {
    width: 100%;
    height: auto;
}
div.sk_member div.sk_row {
    width: 100%;
    padding-bottom: 0;
    cursor: pointer;
    min-height: 81px;
    display: flex;
    gap: 12px;
    align-items: center;
}
div.sk_member div.sk_row p.sk_profile {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background: #e5e5e5;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
div.sk_member div.sk_row div.sk_text_col {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
div.sk_member div.sk_row div.sk_text_col h1 {
    background: #e5e5e5;
    width: 60px;
    height: 13px;
    border-radius: 13px;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
div.sk_member div.sk_row div.sk_text_col span {
    background: #e5e5e5;
    width: 150px;
    height: 13px;
    border-radius: 12px;
    display: flex;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
ul.sk_teamwork_list {
}
ul.sk_teamwork_list li {
}
ul.sk_teamwork_list li p {
    background: #e5e5e5;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
ul.sk_teamwork_list li span {
    background: #e5e5e5;
    height: 10px !important;
    border-radius: 30px;
    width: 59% !important;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
ul.sk_chat_list {
}
ul.sk_chat_list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    width: calc(100% - 32px);
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
    margin-top: 15px;
}
ul.sk_chat_list li div {
    display: flex;
    align-items: center;
    gap: 15px;
}
ul.sk_chat_list li div p {
    min-width: 44px;
    min-height: 44px;
    width: 44px;
    height: 44px;
    background: #e5e5e5;
    border-radius: 100%;
    background-position: center !important;
    background-size: cover !important;
    border: 1px solid #eaeaea;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
ul.sk_chat_list li div span {
    width: 90px;
    background: #e5e5e5;
    height: 16px;
    border-radius: 20px;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
ul.sk_chat_list li h2 {
    width: 30px;
    background: #e5e5e5;
    height: 16px;
    border-radius: 20px;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
div.more_area div.data_save_wrapper div.none_data_save.sk span {
    color: #888888;
    font-size: 12px;
    background: #e5e5e5;
    font-size: 0;
    width: 230px;
    height: 20px;
    border-radius: 20px;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
div.more_area div.data_save_wrapper div.none_data_save.sk a {
    font-size: 0;
    width: 51px;
    height: 20px;
    border-radius: 20px;
    -webkit-animation: skeleton-gradient 1.5s infinite ease-in-out;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
}
@-webkit-keyframes skeleton-gradient {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}

@keyframes skeleton-gradient {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}
